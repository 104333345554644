import {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    multipartPostRequest,
} from '../api';

export const getAccountSettings = async () => {
    return getRequest('/maintenance/account/settings');
};

export const putAccountSettings = async (accountSettings) => {
    return putRequest('/maintenance/account/settings', accountSettings);
};

export const getExternalIdentifierTypes = async (params) => {
    return getRequest('/maintenance/account/external-identifier-types', params);
};

export const getExternalIdentifierType = async (id) => {
    return getRequest('/maintenance/account/external-identifier-types/' + id);
};

export const postExternalIdentifierType = async (externalIdentifierType) => {
    return postRequest(
        '/maintenance/account/external-identifier-types',
        externalIdentifierType
    );
};

export const putExternalIdentifierType = async (externalIdentifierType) => {
    return putRequest(
        '/maintenance/account/external-identifier-types/' +
            externalIdentifierType.id,
        externalIdentifierType
    );
};

export const deleteExternalIdentifierType = async (id) => {
    return deleteRequest(
        '/maintenance/account/external-identifier-types/' + id
    );
};

export const getTagCategories = async (params = {}) => {
    return getRequest('/maintenance/account/tag-categories', params);
};

export const getTagCategory = async (id) => {
    return getRequest('/maintenance/account/tag-categories/' + id);
};

export const postTagCategory = async (tagCategory) => {
    return postRequest('/maintenance/account/tag-categories', tagCategory);
};

export const putTagCategory = async (tagCategory) => {
    return putRequest(
        '/maintenance/account/tag-categories/' + tagCategory.id,
        tagCategory
    );
};

export const deleteTagCategory = async (id) => {
    return deleteRequest('/maintenance/account/tag-categories/' + id);
};

export const getTags = async (tagCategoryId, params) => {
    return getRequest(
        `/maintenance/account/tag-categories/${tagCategoryId}/tags`,
        params
    );
};

export const getTag = async (id) => {
    return getRequest('/maintenance/account/tags/' + id);
};

export const postTag = async (tag) => {
    return postRequest('/maintenance/account/tags', tag);
};

export const putTag = async (tag) => {
    return putRequest('/maintenance/account/tags/' + tag.id, tag);
};

export const deleteTag = async (id) => {
    return deleteRequest('/maintenance/account/tags/' + id);
};

export const getAbsenceReasons = async (params = {}) => {
    return getRequest(`/maintenance/account/absence-reasons`, params);
};

export const getAbsenceReason = async (id) => {
    return getRequest('/maintenance/account/absence-reasons/' + id);
};

export const postAbsenceReason = async (absenceReason) => {
    return postRequest('/maintenance/account/absence-reasons', absenceReason);
};

export const putAbsenceReason = async (absenceReason) => {
    return putRequest(
        '/maintenance/account/absence-reasons/' + absenceReason.id,
        absenceReason
    );
};

export const deleteAbsenceReason = async (id) => {
    return deleteRequest('/maintenance/account/absence-reasons/' + id);
};

export const getTemplateActions = async () => {
    return getRequest(`/maintenance/account/template-actions`);
};

export const getTemplateAction = async (id) => {
    return getRequest('/maintenance/account/template-actions/' + id);
};

export const postTemplateAction = async (data) => {
    return postRequest('/maintenance/account/template-actions', data);
};

export const putTemplateAction = async (data) => {
    return putRequest('/maintenance/account/template-actions/' + data.id, data);
};

export const deleteTemplateAction = async (id) => {
    return deleteRequest('/maintenance/account/template-actions/' + id);
};

export const getPhonebook = async () => {
    return getRequest('/maintenance/account/phonebook');
};

export const getPhoneNumberTypes = async (onlyE164Format) => {
    return getRequest('/maintenance/account/phone-number-type/', {
        onlyE164Format: onlyE164Format,
    });
};

export const getSelectablePhoneNumberTypes = async (onlyE164Format) => {
    const phoneNumberTypes = await getPhoneNumberTypes(onlyE164Format);
    return phoneNumberTypes.filter((item) => item.selectable) ?? {};
};

export const getDefaultPhoneNumberType = async (onlyE164Format) => {
    const phoneNumberTypes = await getPhoneNumberTypes(onlyE164Format);
    return phoneNumberTypes.find((item) => item.isDefault) ?? {};
};

export const getPhoneNumberType = async (id) => {
    return getRequest('/maintenance/account/phone-number-type/' + id);
};

export const postPhoneNumberType = async (phoneNumberType) => {
    return postRequest(
        '/maintenance/account/phone-number-type',
        phoneNumberType
    );
};

export const putPhoneNumberType = async (phoneNumberType) => {
    return putRequest(
        '/maintenance/account/phone-number-type/' + phoneNumberType.id,
        phoneNumberType
    );
};

export const deletePhoneNumberType = async (id) => {
    return deleteRequest('/maintenance/account/phone-number-type/' + id);
};

export const getSubscriptions = async (params) => {
    return getRequest('/maintenance/account/subscriptions', params);
};

export const getSubscription = async (id) => {
    return getRequest('/maintenance/account/subscriptions/' + id);
};

export const postSubscription = async (subscription) => {
    return postRequest('/maintenance/account/subscriptions', subscription);
};

export const putSubscription = async (subscription) => {
    return putRequest(
        '/maintenance/account/subscriptions/' + subscription.id,
        subscription
    );
};

export const deleteSubscription = async (id) => {
    return deleteRequest('/maintenance/account/subscriptions/' + id);
};

export const getSMSTemplates = async () => {
    return getRequest(`/maintenance/account/sms-templates`);
};

export const getSMSTemplate = async (id) => {
    return getRequest('/maintenance/account/sms-templates/' + id);
};

export const postSMSTemplate = async (data) => {
    return postRequest('/maintenance/account/sms-templates', data);
};

export const putSMSTemplate = async (data) => {
    return putRequest('/maintenance/account/sms-templates/' + data.id, data);
};

export const deleteSMSTemplate = async (id) => {
    return deleteRequest('/maintenance/account/sms-templates/' + id);
};

export const getSMSTemplateVariables = async () => {
    return getRequest(`/maintenance/account/sms-templates/variables`);
};

export const getAccountDocuments = async () => {
    return getRequest(`/maintenance/account/documents`);
};

export const postAccountDocument = async (
    _ignored_entity_id,
    file,
    cbProgress,
    abortController
) => {
    let form = new FormData();
    form.append('file', file);
    let customHeaders = {};
    return multipartPostRequest(
        `/maintenance/account/documents`,
        form,
        customHeaders,
        cbProgress,
        abortController
    );
};

export const getAccountDocumentUrl = (
    _ignored_entity_id,
    accountDocumentId,
    accountId
) => {
    return `/api/maintenance/account/documents/${accountDocumentId}?account_id=${accountId}`;
};

export const deleteAccountDocument = async (
    _ignored_entity_id,
    accountDocumentId
) => {
    return deleteRequest(`maintenance/account/documents/${accountDocumentId}`);
};
