import {
    EdsDropdown,
    EdsDropdownType,
    EdsFormColumn,
    EdsFormGroup,
} from '../../../../components';
import { useTranslation } from 'react-i18next';
import { Row } from '@carbon/react';
import {
    defaultDropdownMappingCallback,
    getGroups,
    getProjects,
    getSubgroups,
    getSubprojects,
} from '../../../../features';
import { useEffect, useRef, useState } from 'react';
import { useEffectOnMount } from '../../../../features/react/hooks';
import _ from 'lodash';

export function EdsFormGroupGroup(props) {
    const [formDefinition, setFormDefinition] = useState([]);
    const { t } = useTranslation();
    const [groupId, setGroupId] = useState();
    const [projectId, setProjectId] = useState();
    const [subgroupId, setSubgroupId] = useState();

    const subgroupRef = useRef(null);
    const projectRef = useRef(null);
    const subprojectRef = useRef(null);

    useEffectOnMount(async () => {
        setFormDefinition(await getFormDefinition());
    });

    const getFormDefinition = async () => {
        return {
            ...(!_.isUndefined(props.wizardStepId) && {
                wizardStepId: props.wizardStepId,
            }),

            groupId: {
                value: props.selectedValues?.group,
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'id',
            },
            subgroupId: {
                value: props.selectedValues?.subgroup,
                validation: {
                    type: 'dropdown',
                    required: false,
                },
                mapping: 'id',
            },
            projectId: {
                value: props.selectedValues?.project,
                validation: {
                    type: 'dropdown',
                    required: false,
                },
                mapping: 'id',
            },
            subprojectId: {
                value: props.selectedValues?.subproject,
                validation: {
                    type: 'dropdown',
                    required: false,
                },
                mapping: 'id',
            },
        };
    };

    const updateSubgroups = async () => {
        const subgroups = [];
        let subgroupsResponse = null;
        if (_.isNumber(groupId)) {
            subgroupsResponse = await getSubgroups(groupId);
        }
        if (_.isArray(subgroupsResponse)) {
            subgroupsResponse.map((data) => {
                subgroups.push(defaultDropdownMappingCallback(data));
            });
        }
        subgroupRef.current?.updateItems(subgroups);
    };

    const updateProjects = async () => {
        const projects = [];
        let projectsResponse = null;
        if (_.isNumber(subgroupId)) {
            projectsResponse = await getProjects(subgroupId);
        }
        if (_.isArray(projectsResponse)) {
            projectsResponse.map((data) => {
                projects.push(defaultDropdownMappingCallback(data));
            });
        }
        projectRef.current?.updateItems(projects);
    };

    const updateSubprojects = async () => {
        const subprojects = [];
        let subprojectsResponse = null;
        if (_.isNumber(projectId)) {
            subprojectsResponse = await getSubprojects(projectId);
        }
        if (_.isArray(subprojectsResponse)) {
            subprojectsResponse.map((data) => {
                subprojects.push(defaultDropdownMappingCallback(data));
            });
        }
        subprojectRef.current?.updateItems(subprojects);
    };

    useEffect(() => {
        // Update subgroups on group id change
        updateSubgroups();
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId]);

    useEffect(() => {
        // Update projects on subgroup id change
        updateProjects();
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subgroupId]);

    useEffect(() => {
        // Update subprojects on project id change
        updateSubprojects();
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    return (
        <EdsFormGroup
            label={
                props.label ?? t('db0f6f37ebeb6ea09489124345af2a45', 'Group')
            }
            prefix={props.prefix}
            formDefinition={formDefinition}
            inStep={props.wizardStepId ?? false}
        >
            <Row>
                <EdsFormColumn>
                    <EdsDropdown
                        name={'groupId'}
                        label={t('db0f6f37ebeb6ea09489124345af2a45', 'Group')}
                        type={EdsDropdownType.ComboBox}
                        getDataCallback={getGroups}
                        onChangeCallback={(event) => {
                            setGroupId(event.selectedItem?.id);
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={subgroupRef}
                        name={'subgroupId'}
                        label={t(
                            'ff90800b1b198e93f60b292ace3ffb00',
                            'Subgroup'
                        )}
                        type={EdsDropdownType.ComboBox}
                        disabledOnEmpty={true}
                        onChangeCallback={(event) => {
                            setSubgroupId(event.selectedItem?.id);
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
            </Row>
            <Row>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={projectRef}
                        name={'projectId'}
                        label={t('46f86faa6bbf9ac94a7e459509a20ed0', 'Project')}
                        type={EdsDropdownType.ComboBox}
                        disabledOnEmpty={true}
                        onChangeCallback={(event) => {
                            setProjectId(event.selectedItem?.id);
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={subprojectRef}
                        name={'subprojectId'}
                        label={t(
                            'bb07fd5abbe6511c40c7e036116d57de',
                            'Subproject'
                        )}
                        type={EdsDropdownType.ComboBox}
                        disabledOnEmpty={true}
                    ></EdsDropdown>
                </EdsFormColumn>
            </Row>
        </EdsFormGroup>
    );
}
