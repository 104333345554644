import { getRequest, postRequest, putRequest, deleteRequest } from '../api';

export const getMedicalPriorities = async () => {
    return getRequest('/maintenance/medical/priorities');
};

export const getMedicalPriority = async (id) => {
    return getRequest('/maintenance/medical/priorities/' + id);
};

export const postMedicalPriority = async (medicalPriority) => {
    return postRequest('/maintenance/medical/priorities', medicalPriority);
};

export const putMedicalPriority = async (medicalPriority) => {
    return putRequest(
        '/maintenance/medical/priorities/' + medicalPriority.id,
        medicalPriority
    );
};

export const deleteMedicalPriority = async (id) => {
    return deleteRequest('/maintenance/medical/priorities/' + id);
};

export const getCaregiverTypes = async () => {
    return getRequest('/maintenance/medical/caregiver-types');
};

export const getCaregiverType = async (id) => {
    return getRequest('/maintenance/medical/caregiver-types/' + id);
};

export const postCaregiverType = async (caregiverType) => {
    return postRequest('/maintenance/medical/caregiver-types', caregiverType);
};

export const putCaregiverType = async (caregiverType) => {
    return putRequest(
        '/maintenance/medical/caregiver-types/' + caregiverType.id,
        caregiverType
    );
};

export const deleteCaregiverType = async (id) => {
    return deleteRequest('/maintenance/medical/caregiver-types/' + id);
};

export const getMedicines = async () => {
    return getRequest('/maintenance/medical/medicines');
};

export const getMedicine = async (id) => {
    return getRequest('/maintenance/medical/medicines/' + id);
};

export const postMedicine = async (medicine) => {
    return postRequest('/maintenance/medical/medicines', medicine);
};

export const putMedicine = async (medicine) => {
    return putRequest(
        '/maintenance/medical/medicines/' + medicine.id,
        medicine
    );
};

export const deleteMedicine = async (id) => {
    return deleteRequest('/maintenance/medical/medicines/' + id);
};

export const getMedicalDataCategories = async () => {
    return getRequest('/maintenance/medical/data-categories');
};

export const getMedicalDataCategory = async (id) => {
    return getRequest('/maintenance/medical/data-categories/' + id);
};

export const postMedicalDataCategory = async (medicalDataCategory) => {
    return postRequest(
        '/maintenance/medical/data-categories',
        medicalDataCategory
    );
};

export const putMedicalDataCategory = async (medicalDataCategory) => {
    return putRequest(
        '/maintenance/medical/data-categories/' + medicalDataCategory.id,
        medicalDataCategory
    );
};

export const deleteMedicalDataCategory = async (id) => {
    return deleteRequest('/maintenance/medical/data-categories/' + id);
};

export const getMedicalDataByCategory = async (categoryId) => {
    return getRequest(`/maintenance/medical/data-by-category/${categoryId}`);
};

export const getAllMedicalData = async () => {
    return getRequest('/maintenance/medical/data');
};

export const getMedicalData = async (id) => {
    return getRequest('/maintenance/medical/data/' + id);
};

export const postMedicalData = async (medicalData) => {
    return postRequest('/maintenance/medical/data', medicalData);
};

export const putMedicalData = async (medicalData) => {
    return putRequest(
        '/maintenance/medical/data/' + medicalData.id,
        medicalData
    );
};

export const deleteMedicalData = async (id) => {
    return deleteRequest('/maintenance/medical/data/' + id);
};

export const getDoctorReferences = async () => {
    return getRequest('/maintenance/medical/doctor-references');
};

export const getDoctorReference = async (id) => {
    return getRequest('/maintenance/medical/doctor-references/' + id);
};

export const postDoctorReference = async (data) => {
    return postRequest('/maintenance/medical/doctor-references', data);
};

export const putDoctorReference = async (data) => {
    return putRequest(
        '/maintenance/medical/doctor-references/' + data.id,
        data
    );
};

export const deleteDoctorReference = async (id) => {
    return deleteRequest('/maintenance/medical/doctor-references/' + id);
};
