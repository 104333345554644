import { forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    EdsContainerTable,
    EdsForm,
    EdsFormGroup,
    EdsRadioButton,
    EdsRadioButtonGroup,
    EdsTableCheckmark,
} from '../../..';
import {
    getLogger,
    setEmptyFieldToNull,
    useModal,
    useNavigate,
    useNotifications,
} from '../../../../features';
import _ from 'lodash';
import UmoLinkPersonalCaregiver from './link-personal-caregiver';
import AdditionalInformationFormGroup from './additional-information-form-group';

const logger = getLogger('UmoLinkedPersonalCaregiversTable');

export const UmoLinkedPersonalCaregiversTable = forwardRef(
    (
        {
            clientId,
            getDataCallback,
            postDataCallback,
            onUnlinkClick,
            getLinkDataCallback,
            putLinkDataCallback,
            ...props
        },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();
        const { showFormModal, showLinkModal } = useModal();
        const { showSuccess, showError } = useNotifications();
        const formRef = useRef(null);
        const [title] = useState(
            props.title ??
                t('d16da7fd52500df57ed23e3c34b383d0', 'Personal caregivers')
        );

        const [hideWhenNoData] = useState(props.hideWhenNoData ?? true);
        const [hasData, setHasData] = useState();

        const getData = async () => {
            let data = await getDataCallback();
            setHasData(!_.isEmpty(data));
            return data;
        };

        if (
            hideWhenNoData &&
            !_.isUndefined(hasData) &&
            _.isBoolean(hasData) &&
            !hasData
        ) {
            return null;
        }

        const getCrmLinkFormId = (id) => {
            const idParts = id.split('_');
            if (idParts && idParts[1]) {
                if (idParts[0] === 'personal-caregiver') {
                    return `/crm/client/${clientId}/caregivers/${idParts[1]}`;
                }
                return `/crm/${idParts[0]}/${idParts[1]}`;
            } else {
                logger.log('No id prefix found for caregiver');
                return undefined;
            }
        };

        const linkNewCaregiver = async (form) => {
            if (_.isEmpty(form?.linkInfo?.order)) {
                //order can be empty but when empty it must be set to 0 for UDS
                form.linkInfo.order = 0;
            }

            const postData = setEmptyFieldToNull(form);

            const response = await postDataCallback({
                professionalCaregiverId: postData.selected,
                order: postData.linkInfo.order,
                hasKey: postData.linkInfo.hasKey,
                isNextOfKin: postData.linkInfo.isNextOfKin,
                relationTypeId: postData.linkInfo.relationTypeId,
                travelTimeMinutes: postData.linkInfo.travelTimeMinutes,
            });

            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '71b89dd2a0ab2c2431141a17a75a2fa0',
                    '{{entity}} linked',
                    {
                        entity: t(
                            '367615897ad8d9bdadf89f4557c87a7a',
                            'Personal caregiver'
                        ),
                    }
                ),
            });

            ref.current?.fetchInitData();

            return response;
        };

        const showLinkProfessionalModal = () => {
            const rows = ref.current?.getRows();
            const filterIds = rows
                .filter((r) => r?.id?.startsWith('professional-caregiver_'))
                .map((r) => r?.id.replace('professional-caregiver_', ''));
            showLinkModal({
                title: t(
                    '049af2af85cacecff2ea1d728ace4950',
                    'Link professional caregiver as personal caregiver'
                ),
                tableRef: ref,
                size: 'lg',
                children: (
                    <UmoLinkPersonalCaregiver
                        {...props}
                        onSubmit={async (form) => {
                            return linkNewCaregiver(form);
                        }}
                        filterIds={filterIds}
                    />
                ),
            });
        };

        const showAdditionalInfoModal = async (row) => {
            if (
                !_.isFunction(getLinkDataCallback) ||
                !_.isFunction(putLinkDataCallback)
            ) {
                return;
            }

            let data = ref.current?.getRowData(row.id);

            let linkInfo = await getLinkDataCallback(data.personalCaregiverId);

            const formData = {
                linkInfo: {
                    order: { value: linkInfo.order },
                    hasKey: { value: linkInfo.hasKey },
                    isNextOfKin: { value: linkInfo.isNextOfKin },
                    travelTimeMinutes: { value: linkInfo.travelTimeMinutes },
                    relationTypeId: {
                        value: {
                            id: linkInfo.relationType?.id,
                            text: linkInfo.relationType?.name,
                        },
                    },
                },
            };

            showFormModal({
                title: t(
                    '6274ae44eae5fd8cfeb02d3687b41a85',
                    'Edit personal caregiver link'
                ),
                formData: formData,
                formRef,
                children: (
                    <EdsForm
                        hideDefaultSubmit={true}
                        ref={formRef}
                        initValues={formData}
                        onSubmit={async (_event, form, isValid) => {
                            if (!isValid) {
                                throw false;
                            }

                            let formPutData = {
                                id: data.personalCaregiverId,
                                ...form.linkInfo,
                            };

                            const putData = setEmptyFieldToNull(formPutData);

                            try {
                                const response = await putLinkDataCallback(
                                    putData
                                );

                                showSuccess({
                                    title: t(
                                        '536a056b710e94b16169efd17a4a657b',
                                        'Saved'
                                    ),
                                    content: t(
                                        '9be8b87979790eba7c8d3ab28acdcf4f',
                                        '{{entity}} link is saved',
                                        {
                                            entity: t(
                                                'd16da7fd52500df57ed23e3c34b383d0',
                                                'Personal caregivers'
                                            ),
                                        }
                                    ),
                                });

                                ref.current?.fetchInitData();

                                return response;
                            } catch (error) {
                                showError({
                                    title: t(
                                        'cb5e100e5a9a3e7f6d1fd97512215282',
                                        'Error'
                                    ),
                                    content: t(
                                        'df0a9d46baf7315909e4389a04786e3d',
                                        'Oops something went wrong'
                                    ),
                                });
                                throw error;
                            }
                        }}
                    >
                        <AdditionalInformationFormGroup initValues={formData} />
                    </EdsForm>
                ),
            });
        };

        return (
            <EdsContainerTable
                hidden={_.isUndefined(hasData)}
                title={title}
                ref={ref}
                onRowClick={(row) => {
                    navigate(getCrmLinkFormId(row.id));
                }}
                headers={[
                    {
                        key: 'order',
                        header: '#',
                        sort: 'order',
                        width: '100px',
                    },
                    {
                        key: 'name',
                        header: t('b068931cc450442b63f5b3d276ea4297', 'Name'),
                        sort: 'name',
                    },
                    {
                        key: 'type',
                        header: t('599dcce2998a6b40b1e38e8c6006cb0a', 'Type'),
                        sort: 'type',
                    },
                    {
                        key: 'address',
                        header: t(
                            '884d9804999fc47a3c2694e49ad2536a',
                            'Address'
                        ),
                        sort: 'address',
                    },
                    {
                        key: 'isProfessionalAsPersonal',
                        header: t(
                            '6c3fa464581898b2fbf23c1f29372d86',
                            'Professional as personal'
                        ),
                    },
                    {
                        key: 'hasKey',
                        header: t('3c6e0b8a9c15224a8228b9a98ca1531d', 'Key'),
                        width: '100px',
                    },
                    {
                        key: 'isAvailable',
                        header: t(
                            'e4894ca167b08880bfc35862f18575eb',
                            'Available'
                        ),
                        width: '100px',
                    },
                ]}
                getDataCallback={getData}
                onEditClick={(row) => {
                    showAdditionalInfoModal(row);
                }}
                editLabel={t(
                    '6274ae44eae5fd8cfeb02d3687b41a85',
                    'Edit personal caregiver link'
                )}
                unlinkLabel={t(
                    'f1113e6972183523d6826fcc411c76b4',
                    'Unlink personal caregiver'
                )}
                onHeaderLinkClick={() => {
                    const formData = {
                        selection: 'personal',
                    };

                    showFormModal({
                        newTitle: t(
                            '9d3c3183263588ea08ce34319a530303',
                            'Link personal caregiver'
                        ),
                        formData,
                        formRef,
                        primaryButtonText: 'Next',
                        onSubmitFormResponse: async (response, removeModal) => {
                            if (response === 'professional') {
                                showLinkProfessionalModal();
                            } else if (response === 'personal') {
                                navigate(
                                    `/crm/client/${clientId}/caregivers/new`
                                );
                            }
                            removeModal();
                        },
                        children: (
                            <EdsForm
                                hideDefaultSubmit={true}
                                initValues={formData}
                                ref={formRef}
                                onSubmit={async (_event, form, isValid) => {
                                    if (!isValid) {
                                        throw false;
                                    }
                                    return form['selection'];
                                }}
                            >
                                <EdsFormGroup formDefinition={formData}>
                                    {t(
                                        '308d868eb3edc8bf2d978a3de5b195e5',
                                        'Do you want to create a new personal caregiver, or link a professional caregiver as a personal caregiver?'
                                    )}
                                    <EdsRadioButtonGroup
                                        name={'selection'}
                                        orientation={'vertical'}
                                        legendText={''}
                                    >
                                        <EdsRadioButton
                                            value={'personal'}
                                            labelText={t(
                                                '83d4bbdf58ba7d56f956d1846e3a412a',
                                                'Create new personal caregiver'
                                            )}
                                        />
                                        <EdsRadioButton
                                            value={'professional'}
                                            labelText={t(
                                                '611214a8e38130ab15aebb5601bd3ea3',
                                                'Link existing professional caregiver as personal caregiver'
                                            )}
                                        />
                                    </EdsRadioButtonGroup>
                                </EdsFormGroup>
                            </EdsForm>
                        ),
                    });
                }}
                onUnlinkClick={
                    !_.isFunction(onUnlinkClick)
                        ? undefined
                        : (row) => {
                              const data = ref.current?.getRowData(row.id);
                              return onUnlinkClick({ row: row, data: data });
                          }
                }
                mappingCallback={(responseData) => {
                    return {
                        id: responseData?.clientPersonalCaregiverLinkResponse
                            ?.isProfessionalAsPersonal
                            ? `professional-caregiver_${responseData?.clientPersonalCaregiverLinkResponse?.professionalCaregiverId}`
                            : `personal-caregiver_${responseData?.clientPersonalCaregiverLinkResponse?.personalCaregiverId}`,
                        personalCaregiverId:
                            responseData?.clientPersonalCaregiverLinkResponse
                                ?.personalCaregiverId,
                        order: responseData?.clientPersonalCaregiverLinkResponse
                            ?.order,
                        name: responseData?.personalCaregiverResponse?.person
                            ?.combinedName,
                        type: responseData?.personalCaregiverResponse
                            ?.caregiverType?.name,
                        address:
                            responseData.personalCaregiverResponse
                                ?.combinedAddressField,
                        isProfessionalAsPersonal: (
                            <EdsTableCheckmark
                                value={
                                    responseData
                                        ?.clientPersonalCaregiverLinkResponse
                                        ?.isProfessionalAsPersonal
                                }
                            />
                        ),
                        hasKey: (
                            <EdsTableCheckmark
                                value={
                                    responseData
                                        ?.clientPersonalCaregiverLinkResponse
                                        ?.hasKey
                                }
                            />
                        ),
                        isAvailable: (
                            <EdsTableCheckmark
                                value={
                                    responseData?.personalCaregiverResponse
                                        ?.isAvailable
                                }
                            />
                        ),
                    };
                }}
                {...props}
            />
        );
    }
);
UmoLinkedPersonalCaregiversTable.displayName =
    'UmoLinkedPersonalCaregiversTable';
