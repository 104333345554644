import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '../../../eds/eds-form';
import _ from 'lodash';
import { EdsFormGroup, EdsNotification, EdsWizardStep } from '../../../eds';
import { UmoSearchClientTable } from '../umo-search-client-table';

function SearchStep({ id, selectedRow, baseFilter, ...props }) {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(selectedRow);
    const { updateFormValues, isInvalid } = useForm();

    useEffect(() => {
        props.selectedRowCallback(selected);
    }, [props, selected]);

    useEffect(() => {
        if (!_.isUndefined(selected) && _.isString(selected)) {
            updateFormValues({ ['client.clientId']: selected });
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const formDefinition = {
        wizardStepId: id,
        clientId: {
            validation: {
                required: true,
            },
        },
    };

    return (
        <EdsWizardStep
            id={id}
            label={t('467ac6e261eefe408b7bd81ce93ba08c', 'Search client')}
        >
            <EdsFormGroup
                formDefinition={formDefinition}
                inStep={true}
                prefix="client"
            >
                {isInvalid('client.clientId') && (
                    <EdsNotification
                        notification={{
                            hideCloseButton: true,
                            kind: 'warning',
                            title: t(
                                '4ed43f7e0b98d10c008a47f4eee7face',
                                'Please select a client to link'
                            ),
                            inline: true,
                        }}
                    />
                )}
                <UmoSearchClientTable
                    onRowClick={(row) => {
                        if (row.id !== selected) {
                            setSelected(row.id);
                        }
                    }}
                    onSelectionButtonClick={(row) => {
                        if (row.id !== selected) {
                            setSelected(row.id);
                        }
                    }}
                    selected={[selected]}
                    baseFilter={baseFilter}
                    enableRadioSelection={true}
                    size="md"
                    enableSearchParams={false}
                    searchFilterStore={undefined}
                    fetchServerSideDataOnInit={true}
                    enableSearch={true}
                />
            </EdsFormGroup>
        </EdsWizardStep>
    );
}

export default SearchStep;
