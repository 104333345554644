import { FlexGrid, Form, Stack } from '@carbon/react';
import './eds-form.scss';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import {
    debounce,
    extractFormSchemaFromDefinition,
    getLogger,
    useEffectOnMount,
} from '../../../features';
import EdsFormProvider from './eds-form-provider';
import { useForm } from './';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { EdsButton, EdsButtonSet } from '../eds-button/eds-button';

const logger = getLogger('EdsForm');

const EdsFormContent = forwardRef((props, ref) => {
    const submitTimeoutId = useRef(null);
    const { t } = useTranslation();
    const {
        getMappedForm,
        addFormDefinition,
        setInitFormValues,
        replaceFormValues,
        form,
        checkIsFormChanged,
        checkInputs,
        checkInputsSection,
    } = useForm();

    useEffect(() => {
        addFormDefinition(props.formDefinition);
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formDefinition]);

    useEffectOnMount(() => {
        setInitFormValues(props.initValues);
    });

    const resolveHandleSubmitForm = async (
        resolve,
        handleSubmitFormFunc,
        event
    ) => {
        return resolve(handleSubmitFormFunc(event));
    };

    const submitForm = async (event) => {
        return new Promise((resolve) => {
            debounce(submitTimeoutId, resolveHandleSubmitForm, {
                params: [resolve, handleSubmitForm, event],
            });
        });
    };

    /*eslint no-undef: "off"*/
    const handleSubmitForm = async (event) => {
        const isValid = await checkInputs();
        const mappedForm = await getMappedForm(form);
        if (_.isFunction(props.onSubmit)) {
            return props.onSubmit(event, mappedForm, isValid);
        }

        return true;
    };

    const getState = () => {
        return form;
    };

    const validateFormWithConfig = async (scheme = {}) => {
        logger.log('validateFormWithConfig', scheme);
        return await checkInputsSection(scheme);
    };

    const setSavedState = (state) => {
        if (!_.isUndefined(props.formDefinition)) {
            logger.log(props.formDefinition);
        }
        replaceFormValues(state);
    };

    const getInitialValues = (initialConfig) => {
        return extractFormSchemaFromDefinition(initialConfig);
    };

    const resetForm = () => {
        replaceFormValues(props.initValues);
    };

    const isFormChanged = () => {
        return checkIsFormChanged();
    };

    useImperativeHandle(ref, () => ({
        submitForm,
        isFormChanged,
        getState,
        validateFormWithConfig,
        setSavedState,
        resetForm,
        getInitialValues,
    }));

    return (
        <div className="eds-form">
            <Form
                onSubmit={(event) => {
                    event.preventDefault();
                }}
            >
                <FlexGrid fullWidth>
                    <Stack gap={6}>{props.children}</Stack>
                    {!props.hideDefaultSubmit && (
                        <EdsButtonSet align="right">
                            <EdsButton onClick={submitForm}>
                                {t('43781db5c40ecc39fd718685594f0956', 'Save')}
                            </EdsButton>
                        </EdsButtonSet>
                    )}
                </FlexGrid>
            </Form>
        </div>
    );
});
EdsFormContent.displayName = 'EdsFormContent';

export const EdsForm = forwardRef((props, ref) => {
    return (
        <EdsFormProvider {...props}>
            <EdsFormContent {...props} ref={ref} />
        </EdsFormProvider>
    );
});
EdsForm.displayName = 'EdsForm';
