import { useTranslation } from 'react-i18next';
import { DaysOfTheWeek, getDate, useEffectOnMount } from '../../../../features';
import { EdsContainer } from '../../../eds';
import './umo-availabilities-grid.scss';
import {
    forwardRef,
    Fragment,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import _ from 'lodash';
import { DateTime } from 'luxon';

export const UmoAvailabilitiesGrid = forwardRef(
    ({ getDataCallback }, { tableRef, gridRef }) => {
        const { t } = useTranslation();
        const elementRef = useRef();
        const days = DaysOfTheWeek;
        const visibleHours = 24;
        const rowsPerHour = 2;

        const totalGridItems =
            visibleHours * rowsPerHour * Object.entries(days).length;

        const parseTime = (hours) => {
            const newDateTime = getDate({ plusHours: hours });

            return DateTime.fromJSDate(newDateTime).toFormat(
                t('72afaa6e494ed2dfc89864418115d41f', 'h:mm a')
            );
        };

        const renderedHours = [...Array(visibleHours).keys()].map((hour) => {
            const key = hour < 10 ? `0${hour}` : hour;
            return {
                id: key,
                name: parseTime(hour),
            };
        });

        const renderedDays = Object.entries(DaysOfTheWeek).map((day) => {
            return {
                id: day[0].toLowerCase(),
                name: t(day[1]),
            };
        });

        const [events, setEvents] = useState([]);

        useEffectOnMount(() => {
            if (_.isFunction(getDataCallback)) {
                fetchInitData();
            }
        });

        useImperativeHandle(gridRef, () => ({
            fetchInitData,
            getElement() {
                return elementRef.current;
            },
        }));

        const fetchInitData = async () => {
            const repsonse = await getDataCallback();
            setEvents(repsonse);
        };

        const formatTimeToGrid = (time) => {
            //the grid only allows for whole hours and half time. This method makes corrects any time that does not meet that requirement.
            //in the getTimeStyle it wil be compensated so that the correct time is shown in the grid

            let newTime = time;
            const endDigits = time.slice(-2);

            if (endDigits > 0 && endDigits < 30) {
                newTime = time.replace(`:${endDigits}`, '00');
            }
            if (endDigits > 30 && endDigits <= 59) {
                newTime = time.replace(`:${endDigits}`, '30');
            }
            return newTime.replace(':', '');
        };

        const getTimeOffset = (time) => {
            let endDigits = time.slice(-2);
            if (endDigits > 30 && endDigits < 59) {
                endDigits = endDigits - 30;
            }
            return Math.abs(endDigits);
        };

        const getTimeStyle = (startTime, endTime) => {
            let startTimeOffset = Math.abs(getTimeOffset(startTime));
            if (startTimeOffset === 30) {
                startTimeOffset = 0;
            }

            let endTtimeOffset = Math.abs(getTimeOffset(endTime));
            if (endTtimeOffset === 30) {
                endTtimeOffset = 0;
            }

            const pixelPerMinute = 15 / 30; //15px per 30 minutes

            const pixelsNeededTop = startTimeOffset * pixelPerMinute;
            const pixelsNeededBottom =
                -pixelsNeededTop + endTtimeOffset * pixelPerMinute;

            return {
                marginTop: `calc(${pixelsNeededTop}px)`,
                height: `calc(100% + ${pixelsNeededBottom}px)`,
            };
        };

        if (_.isEmpty(events)) {
            return null;
        }

        return (
            <EdsContainer
                title={t(
                    '71cb1f931336c631500fd6c94212fee0',
                    'Availability view'
                )}
                withPadding={false}
            >
                <div className="availabilities-grid" ref={gridRef}>
                    <ul className="calendar weekly-byhalfhour" ref={elementRef}>
                        {renderedDays.map((day) => (
                            <li className={`day ${day.id}`} key={day.id}>
                                {day.name}
                            </li>
                        ))}

                        {events.map((event, i) => {
                            const colum = event.dayOfWeek.toLowerCase();
                            const startTime = formatTimeToGrid(event.startTime);
                            const endTime = formatTimeToGrid(event.endTime);

                            return (
                                <li
                                    key={`event-${i}`}
                                    className="event"
                                    style={{
                                        gridColumn: colum,
                                        gridRow: `h${startTime} / h${endTime}`,
                                        ...getTimeStyle(
                                            event.startTime,
                                            event.endTime
                                        ),
                                    }}
                                    onClick={() => {
                                        tableRef.current?.onRowClick(event);
                                    }}
                                />
                            );
                        })}

                        {renderedHours.map((hour) => (
                            <Fragment key={`time-${hour.id}`}>
                                <li
                                    className={`time h${hour.id}00`}
                                    key={`time-${hour.id}00`}
                                >
                                    <div className="label">{hour.name}</div>
                                </li>

                                <li
                                    className={`time h${hour.id}30`}
                                    key={`time-${hour.id}30`}
                                ></li>
                            </Fragment>
                        ))}

                        <li className="corner"></li>

                        {[...Array(totalGridItems).keys()].map((key) => {
                            return (
                                <li className="grid" key={`grid-${key}`}></li>
                            );
                        })}
                    </ul>
                </div>
            </EdsContainer>
        );
    }
);
UmoAvailabilitiesGrid.displayName = 'UmoAvailabilitiesGrid';
