import _ from 'lodash';
import './eds-data-grid.scss';
import {
    Column,
    ContainedList,
    ContainedListItem,
    Row,
    FlexGrid,
} from '@carbon/react';
import { EdsTableState } from '../eds-table/eds-table-state/eds-table-state';

export const getValueOrDefault = (field) => {
    if (_.isBoolean(field)) {
        return (
            <EdsTableState
                validState={true}
                invalidState={false}
                state={field}
            />
        );
    }
    return !_.isEmpty(field) ? field : '-';
};

export const getFullPhoneNumber = (contact) => {
    let number = '';
    let phoneNumber = contact?.phoneNumber || contact?.number;
    if (!_.isEmpty(phoneNumber)) {
        number += phoneNumber;

        if (!_.isEmpty(contact?.phoneNumberType?.text)) {
            number += ` - (${contact?.phoneNumberType?.text})`;
        }
    }

    return getValueOrDefault(number);
};

export const getFullStreetName = (address, rangeMode = false) => {
    const street = getValueOrDefault(address?.streetName);
    const number = rangeMode
        ? `${address?.fromHouseNumber}${
              !_.isEmpty(address?.toHouseNumber)
                  ? ` -  ${address?.toHouseNumber}`
                  : ''
          }`
        : getValueOrDefault(address?.houseNumber);

    const streetElements = [street];
    if (number !== '-') {
        streetElements.push(number);
    }

    if (address?.countryId?.isUKAddressFormat) {
        return streetElements.reverse().join(' ');
    } else {
        return streetElements.join(' ');
    }
};

export function EdsDataGrid({
    withPadding = false,
    label,
    gridData,
    ...props
}) {
    const getStyle = () => {
        let classes = ['eds-data-grid'];
        classes.push('hide-header');

        if (withPadding) {
            classes.push('padding');
        }

        if (!_.isUndefined(props.type) && props.type === 'header-summary') {
            classes.push('header-summary');
        }

        if (!_.isUndefined(props.hideLines) && props.hideLines) {
            classes.push('hide-lines');
        }

        return classes.join(' ');
    };

    const getColumnStyle = (styles = {}) => {
        const { width, fullWidth } = styles;
        let columnStyles = {};
        if (!_.isUndefined(width)) {
            columnStyles['maxWidth'] = width;
        }
        if (!_.isUndefined(fullWidth)) {
            columnStyles['maxInlineSize'] = '100%';
        }
        return columnStyles;
    };

    const getListStyle = (styles = {}) => {
        const { fullWidth } = styles;
        let classes = ['list'];
        if (!_.isUndefined(fullWidth) && fullWidth) {
            classes.push('full-width');
        }
        return classes.join(' ');
    };

    const gridList = (rows, styles = {}) => {
        return (
            <span className={getListStyle(gridData?.styles)}>
                <ContainedList
                    kind="on-page"
                    isInset={false}
                    label={label ?? ''}
                    size={props.size ?? 'md'}
                    style={getColumnStyle(gridData?.styles)}
                >
                    {rows.map((row, index) => (
                        <ContainedListItem key={index}>
                            <FlexGrid>
                                <Row>
                                    <Column
                                        sm={2}
                                        md={4}
                                        lg={8}
                                        style={getColumnStyle(styles?.key)}
                                    >
                                        {row.key}
                                    </Column>
                                    <Column
                                        sm={2}
                                        md={4}
                                        lg={8}
                                        style={getColumnStyle(styles?.value)}
                                    >
                                        {row.value ?? (row.key ? '-' : '')}
                                    </Column>
                                    {row.details && (
                                        <Column>{row.details}</Column>
                                    )}
                                </Row>
                            </FlexGrid>
                        </ContainedListItem>
                    ))}
                </ContainedList>
            </span>
        );
    };

    if (!gridData || !gridData.rows) {
        return null;
    }

    return (
        <div className={getStyle()}>
            {!_.isEmpty(gridData.rows.left) &&
                gridList(gridData.rows.left, gridData.styles?.left)}
            {!_.isEmpty(gridData.rows.right) &&
                gridList(gridData.rows.right, gridData.styles?.right)}
        </div>
    );
}

export function EdsDataGridHeader(props) {
    return (
        <EdsDataGrid
            type={'header-summary'}
            size={'sm'}
            withPadding={false}
            hideLines={true}
            {...props}
        ></EdsDataGrid>
    );
}
export function EdsDataGridWizardSummary(props) {
    return <EdsDataGrid withPadding={true} {...props}></EdsDataGrid>;
}
