import './features/sentry';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { routes } from './router';
import './assets/scss/main.scss';
import './features/i18n';

ReactDOM.createRoot(document.getElementById('root')).render(
    <RouterProvider router={routes} />
);
