import { useEffect, useState } from 'react';
import { EdsFormGroup, EdsNotification, EdsWizardStep } from '../../../eds';
import { useTranslation } from 'react-i18next';
import { UmoSearchResidencesTable } from '../umo-search-residence-table';
import _ from 'lodash';

import { useForm } from '../../../eds/eds-form';

function SearchStep({ id, selectedRow, baseFilter, ...props }) {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(selectedRow);
    const { updateFormValues, isInvalid } = useForm();

    useEffect(() => {
        props.selectedRowCallback(selected);
    }, [props, selected]);

    useEffect(() => {
        if (!_.isUndefined(selected) && _.isString(selected)) {
            updateFormValues({ ['residence.residenceId']: selected });
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const formDefinition = {
        wizardStepId: id,
        residenceId: {
            validation: {
                required: true,
            },
        },
    };

    return (
        <EdsWizardStep
            id={id}
            label={t('7dee0ebd9cf4b2374a2b03785984182b', 'Search residence')}
        >
            <EdsFormGroup
                formDefinition={formDefinition}
                inStep={true}
                prefix="residence"
            >
                {isInvalid('residence.residenceId') && (
                    <EdsNotification
                        notification={{
                            hideCloseButton: true,
                            kind: 'warning',
                            title: t(
                                'fabc0302e3fb38da5355d78e7f1bdc67',
                                'Please select a residence to link'
                            ),
                            inline: true,
                        }}
                    />
                )}

                <UmoSearchResidencesTable
                    onRowClick={(row) => {
                        if (row.id !== selected) {
                            setSelected(row.id);
                        }
                    }}
                    onSelectionButtonClick={(row) => {
                        if (row.id !== selected) {
                            setSelected(row.id);
                        }
                    }}
                    selected={[selected]}
                    enableRadioSelection={true}
                    size="md"
                    enableSearchParams={false}
                    searchFilterStore={undefined}
                    fetchServerSideDataOnInit={true}
                    enableSearch={true}
                    baseFilter={baseFilter}
                />
            </EdsFormGroup>
        </EdsWizardStep>
    );
}

export default SearchStep;
