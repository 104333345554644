import * as Yup from 'yup';
import _ from 'lodash';
import { utils as intlTelInputUtils } from 'intl-tel-input/intlTelInputWithUtils';
import { getLogger } from './logger';

let logger = getLogger('FormValidation');

const isValidInitValues = (initVal) => {
    return initVal && _.isPlainObject(initVal);
};

const flattenValue = (inputValue) => {
    if (_.isUndefined(inputValue) || _.isNull(inputValue)) {
        return '';
    }

    if (
        !_.isObject(inputValue) ||
        _.isArray(inputValue) ||
        inputValue instanceof Date ||
        inputValue instanceof File
    ) {
        return inputValue;
    } else if ('value' in inputValue) {
        return inputValue.value;
    } else {
        let returnData = {};
        for (const [key, value] of Object.entries(inputValue)) {
            if (key === 'validation' || key === 'mapping') {
                return;
            }
            returnData[key] = flattenValue(value);
        }
        return returnData;
    }
};

export const getFormFromDefinition = (
    formDefinition,
    initFormValues,
    formValues = {}
) => {
    let form = {};
    if (!isValidInitValues(formDefinition)) {
        return form;
    }

    const combinedObj = { ...formDefinition, ...initFormValues, ...formValues };

    Object.keys(combinedObj).map((item) => {
        let definition = combinedObj[item];
        form[item] = flattenValue(definition);
    });
    return form;
};

const getTypeForRule = (item, required = false) => {
    let type = '';
    if ('validation' in item && 'type' in item.validation) {
        type = item.validation.type;
    } else if ('value' in item) {
        type = typeof item.value;
    }

    if (type.length === 0) {
        type = 'string';
    }

    let generateRule;

    switch (type) {
        case 'string': {
            generateRule = Yup.string();
            break;
        }

        case 'datepicker':
        case 'datetimepicker': {
            generateRule = Yup.string();
            break;
        }

        case 'email': {
            const emailRegex = /^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            generateRule = Yup.string()
                .email()
                .matches(emailRegex, '636e4a8a5f41d718beb2199623cb7ab7'); // 'Please enter a valid email address'
            break;
        }

        case 'boolean': {
            generateRule = Yup.boolean();
            break;
        }

        case 'number': {
            if (required) {
                generateRule = Yup.number();
            } else {
                const numberOrEmptyRegex = /^(?:\d*|)$/;
                generateRule = Yup.string().matches(
                    numberOrEmptyRegex,
                    'd1d26283539c047d5c72c2b0079c5156'
                ); // 'Please enter a number'
            }
            break;
        }

        case 'cost': {
            const doubleOrEmptyRegex = /^(?:\d+?(?:\.\d\d|)|)$/;
            generateRule = Yup.string().matches(
                doubleOrEmptyRegex,
                '41bd6a7f9a27479c2821df1d147ee633'
            ); // "Please enter a cost, for example '12' or '7.50'"
            break;
        }

        case 'dropdown': {
            if (required) {
                generateRule = Yup.object()
                    .shape({})
                    .test(
                        'not-empty',
                        '3f5f7f01887d47e3f71bf06e5f475e41',
                        // 'Field is required'
                        (value) => {
                            let mapping = item.mapping ?? 'id';
                            return !_.isNil(value?.[mapping]);
                        }
                    );
            } else {
                generateRule = Yup.object();
            }
            break;
        }

        case 'multiselect': {
            if (required) {
                generateRule = Yup.array()
                    .of(Yup.object().shape({}))
                    .test(
                        'not-empty',
                        '3f5f7f01887d47e3f71bf06e5f475e41',
                        // 'Field is required'
                        (value) => {
                            return !_.isEmpty(value);
                        }
                    );
            } else {
                generateRule = Yup.array().of(Yup.object());
            }
            break;
        }

        case 'phone-number': {
            generateRule = Yup.string().test({
                name: 'phone-number-validation',
                test(value, testContext) {
                    let validateE164Format = true;

                    //first check for the value in the testContext form.  Otherwise check the parent.
                    //Parent.phoneNumberTypeId is only available if the check it triggered from the phoneNumberTypeId field

                    const options = testContext?.options;

                    if (!_.isUndefined(options) && !_.isEmpty(options)) {
                        let phoneNumberTypeId;
                        const formPhoneNumberTypeId =
                            options.context?.form?.phoneNumberTypeId;

                        const parentPhoneNumberTypeId =
                            options.parent?.phoneNumberTypeId;

                        if (!_.isUndefined(parentPhoneNumberTypeId)) {
                            phoneNumberTypeId = parentPhoneNumberTypeId;
                        } else {
                            phoneNumberTypeId = formPhoneNumberTypeId;
                        }

                        if (
                            !_.isEmpty(phoneNumberTypeId) &&
                            !_.isUndefined(phoneNumberTypeId.validateE164Format)
                        ) {
                            validateE164Format =
                                phoneNumberTypeId.validateE164Format;
                        }
                    }

                    if (validateE164Format) {
                        let validatedNumber =
                            _.isUndefined(value) ||
                            _.isEmpty(value) ||
                            (intlTelInputUtils &&
                                intlTelInputUtils.isValidNumber(value));

                        if (!validatedNumber) {
                            //possible errors from getValidationError
                            // "IS_POSSIBLE": 0,
                            // "INVALID_COUNTRY_CODE": 1,
                            // "TOO_SHORT": 2,
                            // "TOO_LONG": 3,
                            // "IS_POSSIBLE_LOCAL_ONLY": 4,
                            // "INVALID_LENGTH": 5,

                            const validationError =
                                intlTelInputUtils.getValidationError(value);

                            //if intlTelInputUtils thinks the number is incorrect. check again with this regex. This should allow for M2M phone numbers like +327700010008859
                            if (
                                validationError === 1 ||
                                validationError === 3
                            ) {
                                //should return 3 but due to an bug it returns always 1 . invalid country code

                                //  TOO_LONG = 3,
                                const phoneRegex = /^\+[1-9]\d{12,14}$/g;
                                validatedNumber = phoneRegex.test(value);
                            }
                        }

                        if (!validatedNumber) {
                            return testContext.createError({
                                message: '72e4cbc0883679025771510359937a07', // 'Please enter a valid phone number'
                            });
                        }
                    } else {
                        const numberOrEmptyRegex = /^(?:\d*|)$/;
                        if (!numberOrEmptyRegex.test(value)) {
                            return testContext.createError({
                                message: '4cd8d22c5d1a67c0851c601e840d36a7', // 'Please enter a valid service number (only digits)'
                            });
                        }
                    }

                    return true;
                },
            });
            break;
        }

        case 'files': {
            generateRule = Yup.mixed().test(
                'has-files',
                'b87a9a67ba05171eeffb3ede04f5d457', // 'File is required'
                (value) => {
                    if (_.isArray(value) && !_.isEmpty(value)) {
                        return true;
                    }
                    return !required;
                }
            );

            let maxCount = item.validation?.maxCount;
            if (maxCount) {
                generateRule = generateRule.test(
                    'max-file-count',
                    'e9ccf1f36775b6dce3955c448ae29e6a', // 'Maximum file count exceeded'
                    (value) => {
                        return _.isArray(value) && value.length <= maxCount;
                    }
                );
            }

            let maxFileSize = item.validation?.maxFileSize;
            if (maxFileSize) {
                generateRule = generateRule.test(
                    'max-file-size',
                    'a0884fd29d9c60f14f22b31ff7189cfc', // 'File exceeds maximum file size'
                    (value) => {
                        if (!_.isArray(value) || _.isEmpty(value)) {
                            return true;
                        }
                        for (let file of value) {
                            if (file.size > maxFileSize) {
                                return false;
                            }
                        }
                        return true;
                    }
                );
            }

            let maxTotalSize = item.validation?.maxTotalSize;
            if (maxTotalSize) {
                generateRule = generateRule.test(
                    'max-total-size',
                    '64352602e1ad7b9c201249eaa57600a9', // 'Maximum total file size exceeded'
                    (value) => {
                        if (!_.isArray(value) || _.isEmpty(value)) {
                            return true;
                        }
                        let totalSize = 0;
                        for (let file of value) {
                            totalSize += file.size;
                        }
                        return totalSize <= maxTotalSize;
                    }
                );
            }

            let allowedTypes = item.validation?.allowedTypes;
            if (_.isArray(allowedTypes)) {
                generateRule = generateRule.test(
                    'file-type',
                    'ede551916187bbec49bf1a46e7c1601d', // 'File type is not allowed'
                    (value) => {
                        if (!_.isArray(value) || _.isEmpty(value)) {
                            return true;
                        }
                        for (let file of value) {
                            if (allowedTypes.indexOf(file.type) === -1) {
                                return false;
                            }
                        }
                        return true;
                    }
                );
            }

            break;
        }

        case 'hour-minute': {
            const regex = /^([0-9]{1,8}):[0-5][0-9]$/;
            generateRule = Yup.string().test(
                'hour-minute',
                '39579921b57b3bb3aa0e4d57fbf891eb', // 'Invalid format (HH:mm)'
                (value) => {
                    if (!value || _.isEmpty(value)) {
                        return true;
                    }
                    if (!regex.test(value)) {
                        return false;
                    }

                    return true;
                }
            );
            break;
        }

        default: {
            logger.info(
                'no custom type defined for "' +
                    type +
                    '" use default .string()'
            );
            generateRule = Yup.string();
            break;
        }
    }

    generateRule = required
        ? generateRule.required()
        : generateRule.notRequired();

    return generateRule;
};

const setMaxRule = (generateRule, max) => {
    return generateRule.max(max);
};

const setMinRule = (generateRule, min) => {
    return generateRule.min(min);
};

export const extractFormSchemaFromDefinition = (formDefinitions) => {
    let schemaRules = {};

    Object.keys(formDefinitions).map((item) => {
        const formDefinition = formDefinitions[item];

        if (
            _.isPlainObject(formDefinition) &&
            Object.keys(formDefinition).length > 0
        ) {
            //check if validation exist in formDefinition
            if ('validation' in formDefinition) {
                const validationRules = formDefinition.validation;

                let generateRule = getTypeForRule(
                    formDefinition,
                    validationRules.required
                );

                if (validationRules.max && _.isNumber(validationRules.max)) {
                    generateRule = setMaxRule(
                        generateRule,
                        validationRules.max
                    );
                }

                if (validationRules.min && _.isNumber(validationRules.min)) {
                    generateRule = setMinRule(
                        generateRule,
                        validationRules.min
                    );
                }

                if (_.isArray(validationRules.tests)) {
                    validationRules.tests.map((test) => {
                        if (
                            test &&
                            _.isString(test.name) &&
                            _.isString(test.message) &&
                            _.isFunction(test.func)
                        ) {
                            generateRule = generateRule.test(
                                test.name,
                                test.message,
                                test.func
                            );
                        }
                    });
                }

                schemaRules[item] = generateRule;
            } else {
                // let hasChildrenWithValidation = false;
                //item must be ignored or it can be a subgroup with own validation rules
                Object.keys(formDefinition).map((child) => {
                    if (!_.isPlainObject(formDefinition[child])) {
                        return;
                    }
                    if ('validation' in formDefinition[child]) {
                        schemaRules[item] =
                            extractFormSchemaFromDefinition(formDefinition);
                    }
                });
            }
        }
    });
    return Yup.object(schemaRules);
};
