import { useRef } from 'react';
import {
    EdsContainerTable,
    EdsDataGrid,
    EdsTableCheckmark,
    EdsTableDate,
    EdsTableFilterType,
} from '../../..';
import { useTranslation } from 'react-i18next';
import {
    deleteNote,
    getLogger,
    getNote,
    NotesCreatePermissionSet,
    NotesDeletePermissionSet,
    NotesUpdatePermissionSet,
    useModal,
} from '../../../../features';
import UmoNotesForm from './umo-notes-form';
import _ from 'lodash';

let logger = getLogger('UmoNotesTable');

export const UmoNotesTable = ({
    title,
    postDataCallback,
    getDataCallback,
    showHighPriority = false,
    visibleHeaderKeys = [
        'sortIndex',
        'subject',
        'validFrom',
        'validTo',
        'content',
    ],
}) => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const tableRef = useRef(null);
    const { showDeleteModal, showFormModal, showModal } = useModal();

    const headers = [
        {
            key: 'sortIndex',
            header: '#',
            width: '100px',
        },
        {
            key: 'subject',
            header: t('b5e3374e43f6544852f7751dfc529100', 'Subject'),
            search: 'subject',
        },
        {
            key: 'validFrom',
            header: t('167b2d7828511b4bdbb6d642029bdaff', 'Valid from'),
            width: '200px',
        },
        {
            key: 'validTo',
            header: t('6e1f5c87ac9e0d969abb679a0603b54d', 'Valid to'),
            width: '200px',
        },
        {
            key: 'isHighPriority',
            header: t('bc77d45d120bf8057eb10fa8602e186b', 'High priority'),
            width: '200px',
        },
        {
            key: 'content',
            header: t('aad653ca3ee669635f2938b73098b6d7', 'Note'),
            width: '50%',
        },
    ];

    const getVisibleHeaders = () => {
        if (!visibleHeaderKeys) {
            return headers;
        }

        return visibleHeaderKeys.map((key) =>
            headers.find((h) => h.key === key)
        );
    };

    const notesMappingCallback = (responseData) => {
        return {
            id: responseData.id,
            sortIndex: responseData.sortIndex,
            subject: responseData.subject,
            validFrom: <EdsTableDate value={responseData.validFrom} />,
            validTo: <EdsTableDate value={responseData.validTo} />,
            isHighPriority: (
                <EdsTableCheckmark value={responseData.isHighPriority} />
            ),
            content: _.truncate(responseData.content, {
                length: 250,
            }),
        };
    };

    const getDataRow = async (row, edit) => {
        try {
            let note = await getNote(row.id);

            if (edit) {
                showDataModal({
                    id: {
                        value: note.id,
                    },
                    sortIndex: {
                        value: note.sortIndex,
                    },
                    subject: {
                        value: note.subject,
                    },
                    validFrom: {
                        value: note.validFrom,
                    },
                    validTo: {
                        value: note.validTo,
                    },
                    content: {
                        value: note.content,
                    },
                    isHighPriority: {
                        value: note.isHighPriority,
                    },
                });
            } else {
                showModal({
                    title: `${t(
                        'aad653ca3ee669635f2938b73098b6d7',
                        'Note'
                    )} - ${note.subject}`,
                    children: (
                        <>
                            <EdsDataGrid
                                gridData={{
                                    styles: {},
                                    rows: {
                                        left: [
                                            showHighPriority && {
                                                ...{
                                                    key: t(
                                                        'bc77d45d120bf8057eb10fa8602e186b',
                                                        'High priority'
                                                    ),
                                                    value: (
                                                        <div className="no-padding">
                                                            <EdsTableCheckmark
                                                                value={
                                                                    note.isHighPriority
                                                                }
                                                            />
                                                        </div>
                                                    ),
                                                },
                                            },
                                            {
                                                key: t(
                                                    '70a17ffa722a3985b86d30b034ad06d7',
                                                    'Order'
                                                ),
                                                value: note.sortIndex,
                                            },
                                            {
                                                key: t(
                                                    'b5e3374e43f6544852f7751dfc529100',
                                                    'Subject'
                                                ),
                                                value: note.subject,
                                            },
                                            {
                                                key: t(
                                                    '167b2d7828511b4bdbb6d642029bdaff',
                                                    'Valid from'
                                                ),
                                                value: (
                                                    <EdsTableDate
                                                        value={note.validFrom}
                                                    />
                                                ),
                                            },

                                            {
                                                key: t(
                                                    '6e1f5c87ac9e0d969abb679a0603b54d',
                                                    'Valid to'
                                                ),
                                                value: (
                                                    <EdsTableDate
                                                        value={note.validTo}
                                                    />
                                                ),
                                            },
                                        ],
                                        right: [],
                                    },
                                }}
                            />

                            <EdsDataGrid
                                gridData={{
                                    styles: {
                                        fullWidth: true,
                                    },
                                    rows: {
                                        left: [
                                            {
                                                key: t(
                                                    'aad653ca3ee669635f2938b73098b6d7',
                                                    'Note'
                                                ),
                                                value: (
                                                    <pre
                                                        style={{
                                                            whiteSpace:
                                                                'break-spaces',
                                                        }}
                                                    >
                                                        {note.content}
                                                    </pre>
                                                ),
                                            },
                                        ],
                                    },
                                }}
                            />
                        </>
                    ),
                    secondaryButtonText: t(
                        '716f6b30598ba30945d84485e61c1027',
                        'Close'
                    ),

                    hidePrimaryButton: true,
                });
            }
        } catch (ex) {
            logger.warn('getNote UDS Exception:', ex);
        }
    };

    const showDataModal = (note = {}) => {
        showFormModal({
            newTitle: t('855822db81db1777d2fe56140d5b3a90', 'Create note'),
            editTitle: t('527b33b18e2fa2a135ca2570368ac9b2', 'Edit note'),
            formData: note,
            formRef,
            tableRef,
            onSubmitFormResponse: async (response, removeModal) => {
                if (response) {
                    tableRef.current?.doSearch();
                }
                removeModal();
            },
            children: (
                <UmoNotesForm
                    postDataCallback={postDataCallback}
                    ref={formRef}
                    initValues={note}
                    hideDefaultSubmit={true}
                    showHighPriority={showHighPriority}
                ></UmoNotesForm>
            ),
        });
    };

    return (
        <EdsContainerTable
            title={title ?? t('4358b5009c67d0e31d7fbf1663fcd3bf', 'Notes')}
            ref={tableRef}
            headers={getVisibleHeaders()}
            getDataCallback={async (params = {}) => {
                return getDataCallback({
                    isValid: params.showInvalid ? null : true,
                    searchSubjectTerm: !_.isEmpty(params.searchTerm)
                        ? params.searchTerm
                        : null,
                });
            }}
            enableSearchParams={false}
            mappingCallback={notesMappingCallback}
            onRowClick={(row) => {
                getDataRow(row, false);
            }}
            onEditClick={(row) => {
                getDataRow(row, true);
            }}
            onDeleteClick={(row) => {
                const subject = tableRef.current?.getColumnValueString(
                    row.id,
                    'subject'
                );
                showDeleteModal({
                    name: subject,
                    tableRef,
                    row,
                    deleteHandler: async () => {
                        await deleteNote(row.id);
                    },
                });
            }}
            onHeaderAddClick={() => showDataModal()}
            permissions={{
                new: NotesCreatePermissionSet,
                edit: NotesUpdatePermissionSet,
                delete: NotesDeletePermissionSet,
            }}
            searchClientSide={false}
            enableSearch={true}
            hasPaging={false}
            availableFilters={[
                {
                    id: 'showInvalid',
                    name: t(
                        'f4b50fb47dcc58beef451ad3caead371',
                        'Also show invalid items'
                    ),
                    type: EdsTableFilterType.YesNo,
                    showOperator: false,
                    customQueryParamsFormatter: (params, values) => {
                        if (!_.isUndefined(values) && _.isBoolean(values)) {
                            return { ...params, showInvalid: values };
                        }
                        return params;
                    },
                },
            ]}
        />
    );
};

export const UmoNotesTableForClient = ({ ...props }) => {
    return (
        <UmoNotesTable
            {...props}
            showHighPriority={true}
            visibleHeaderKeys={[
                'sortIndex',
                'subject',
                'validFrom',
                'validTo',
                'isHighPriority',
                'content',
            ]}
        />
    );
};

export const UmoNotesTableForScheme = ({ ...props }) => {
    return (
        <UmoNotesTable
            {...props}
            showHighPriority={true}
            visibleHeaderKeys={[
                'sortIndex',
                'subject',
                'validFrom',
                'validTo',
                'isHighPriority',
                'content',
            ]}
        />
    );
};
