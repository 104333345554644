import {
    AccountIdApiSetter,
    AuthProvider,
    ChannelsProvider,
    ModalProvider,
    NotificationsProvider,
    PermissionsProvider,
} from './features';

export const ProviderTree = ({ children }) => {
    return (
        <NotificationsProvider>
            <AuthProvider>
                <AccountIdApiSetter>
                    <PermissionsProvider>
                        <ModalProvider>
                            <ChannelsProvider>{children}</ChannelsProvider>
                        </ModalProvider>
                    </PermissionsProvider>
                </AccountIdApiSetter>
            </AuthProvider>
        </NotificationsProvider>
    );
};
