import * as Sentry from '@sentry/react';
import { safeJoin } from '@sentry/core';
import React from 'react';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import { isDevMode } from '../utils';

if (!isDevMode) {
    Sentry.init({
        /*eslint no-undef: "off"*/
        dsn: sentry_dsn,
        environment: sentry_env,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],
        tracesSampleRate: 1.0,
    });

    const setupConsole = (level) => {
        return (...args) => {
            Sentry.addBreadcrumb({
                category: 'console',
                message: safeJoin(args, ' '),
                data: {
                    arguments: args,
                    logger: 'console',
                },
                level: level,
            });
        };
    };
    console.log = setupConsole('debug');
    console.info = setupConsole('info');
    console.warn = setupConsole('warning');
    console.error = setupConsole('error');
}
