import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './context';
import _ from 'lodash';
import { getLogger } from '../utils';
import { getLastUsedAccountId } from '../utils/last-used-tenants';
import { getRefererInfo } from '../utils/referer-redirect';
import { useEffect, useState } from 'react';

const logger = getLogger('AccountIdSelector');

export const AccountIdSelector = ({ children }) => {
    const { isAuthenticated, attributes, accountId, selectAccount } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [refererInfo, setRefererInfo] = useState();

    logger.log('accountId', accountId);

    useEffect(() => {
        const navigateToReferer = async () => {
            if (!_.isUndefined(refererInfo?.accountId)) {
                selectAccount(refererInfo?.accountId);
            }

            if (!_.isUndefined(refererInfo?.referer)) {
                navigate(refererInfo?.referer);
            }

            setRefererInfo(undefined);
        };

        if (!_.isUndefined(refererInfo)) {
            navigateToReferer();
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refererInfo]);

    if (!isAuthenticated) {
        logger.log('Not authenticated');
        return children;
    }

    if (!location.pathname.startsWith('/account')) {
        return children;
    }

    if (!_.isUndefined(accountId)) {
        let account = attributes.accounts?.find(
            (item) => `${item.id}` === accountId
        );

        if (!_.isUndefined(account)) {
            logger.log('Account is valid');
            return children;
        } else {
            logger.log('Redirect to select default account');
            return <Navigate to={`/account`} />;
        }
    }

    const newRefererInfo = getRefererInfo(true);
    if (!_.isEmpty(newRefererInfo.referer)) {
        setRefererInfo(newRefererInfo);
    } else {
        const lastUsedAccountId = getLastUsedAccountId(attributes.tenant_id);
        if (
            !_.isUndefined(lastUsedAccountId) &&
            !_.isUndefined(
                attributes.accounts?.find(
                    (item) => `${item.id}` === lastUsedAccountId
                )
            )
        ) {
            logger.log('Redirect to last used account');
            return <Navigate to={`/account/${lastUsedAccountId}`} />;
        } else {
            let defaultAccount = attributes.accounts?.find(
                (account) => account.isDefault
            );
            if (!_.isUndefined(defaultAccount)) {
                logger.log('Redirect to default account');
                return <Navigate to={`/account/${defaultAccount.id}`} />;
            } else {
                let firstAccount = _.head(attributes.accounts);
                if (!_.isUndefined(firstAccount)) {
                    logger.log('Redirect to first account');
                    return <Navigate to={`/account/${firstAccount.id}`} />;
                }
            }
        }
    }
    return children;
};
