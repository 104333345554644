export const ClientCreatePermissionSet = 'umo.client.create';
export const ClientReadPermissionSet = 'umo.client.read';
export const ClientUpdatePermissionSet = 'umo.client.update';
export const ClientDeletePermissionSet = 'umo.client.delete';

export const ClientExtensionCreatePermissionSet = 'umo.client.extension.create';
export const ClientExtensionReadPermissionSet = 'umo.client.extension.read';
export const ClientExtensionUpdatePermissionSet = 'umo.client.extension.update';
export const ClientExtensionDeletePermissionSet = 'umo.client.extension.delete';

export const ClientMasterDataReadPermissionSet = 'umo.clientmasterdata.read';
