import { useEffect, useRef, useState } from 'react';
import { EdsFormGroup, EdsNotification, EdsWizardStep } from '../../../eds';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useForm } from '../../../eds/eds-form';
import { UmoSearchMedicalDataTable } from '../umo-search-medical-data';

function SearchStep({
    additionalDataFilterCallback,
    id,
    selectedRow,
    baseFilter,
    medicalPriorityRef,
    ...props
}) {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(selectedRow);
    const { updateFormValues, isInvalid } = useForm();
    const tableRef = useRef(null);

    useEffect(() => {
        props.selectedRowCallback(selected);
    }, [props, selected]);

    useEffect(() => {
        if (!_.isUndefined(selected) && _.isString(selected)) {
            updateFormValues({ ['medicalData.medicalDataId']: selected });
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const formDefinition = {
        wizardStepId: id,
        medicalDataId: {
            validation: {
                required: true,
            },
        },
    };

    const updateSelected = (id) => {
        setSelected(id);

        let data = tableRef.current?.getRowData(id);
        if (!_.isNil(data?.medicalPriorityId)) {
            medicalPriorityRef.current?.updateSelectedItem(
                data.medicalPriorityId
            );
        }
    };

    return (
        <EdsWizardStep
            id={id}
            label={t('0aa943ea177c03b1d6f55216145a3a05', 'Search medical data')}
        >
            <EdsFormGroup
                formDefinition={formDefinition}
                inStep={true}
                prefix="medicalData"
            >
                {isInvalid('medicalData.medicalDataId') && (
                    <EdsNotification
                        notification={{
                            hideCloseButton: true,
                            kind: 'warning',
                            title: t(
                                '3303ae57c66cf00d0b13f2624516dc77',
                                'Please select medical data to link'
                            ),
                            inline: true,
                        }}
                    />
                )}

                <UmoSearchMedicalDataTable
                    ref={tableRef}
                    onRowClick={(row) => {
                        if (row.id !== selected) {
                            updateSelected(row.id);
                        }
                    }}
                    onSelectionButtonClick={(row) => {
                        if (row.id !== selected) {
                            updateSelected(row.id);
                        }
                    }}
                    selected={[selected]}
                    enableRadioSelection={true}
                    size="md"
                    enableSearchParams={false}
                    searchFilterStore={undefined}
                    fetchServerSideDataOnInit={true}
                    enableSearch={true}
                    baseFilter={baseFilter}
                    additionalDataFilterCallback={additionalDataFilterCallback}
                />
            </EdsFormGroup>
        </EdsWizardStep>
    );
}

export default SearchStep;
