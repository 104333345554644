import { useTranslation } from 'react-i18next';
import {
    EdsOptionalLink,
    EdsSortDirection,
    EdsTable,
    EdsTableFilterType,
} from '../../../eds';
import {
    getResidenceSearch,
    getSchemes,
    ResidenceCreatePermissionSet,
} from '../../../../features';
import { searchFilterStore } from '../../../../features/uds/residence';
import _ from 'lodash';

export const UmoSearchResidencesTable = ({ baseFilter, ...props }) => {
    const { t } = useTranslation();

    const getDataCallback = async ({ $filter, ...params }) => {
        return getResidenceSearch({
            ...params,
            $filter: addBaseFilter($filter),
        });
    };

    const addBaseFilter = (filter) => {
        const filterParts = [];

        if (!_.isUndefined(filter)) {
            filterParts.push(filter);
        }

        if (baseFilter) {
            filterParts.push(baseFilter);
        }

        if (_.isEmpty(filterParts)) {
            return undefined;
        }

        return filterParts.join(' and ');
    };

    const mappingCallback = (data) => {
        return {
            id: data.id,
            address: (
                <EdsOptionalLink
                    id={data?.id}
                    to={`/crm/residence/${data?.id}`}
                    label={data?.combinedAddressField}
                />
            ),
            city: data?.address.city?.name,
            scheme: (
                <EdsOptionalLink
                    id={data?.schemeId}
                    to={`/crm/scheme/${data?.schemeId}`}
                    label={data?.schemeName}
                />
            ),
            residenceType: data?.residenceType?.name,
            defaultDevice: (
                <EdsOptionalLink
                    id={data?.defaultDisplayedDeviceId}
                    to={`/crm/device/${data?.defaultDisplayedDeviceId}`}
                    label={data.defaultDisplayedDeviceCode}
                />
            ),
        };
    };

    const headers = [
        {
            key: 'address',
            header: t('884d9804999fc47a3c2694e49ad2536a', 'Address'),
            sort: 'address/streetName,address/houseNumber,address/city/name,address/postalCode',
            isDefaultSort: EdsSortDirection.Ascending,
        },
        {
            key: 'city',
            header: t('4ed5d2eaed1a1fadcc41ad1d58ed603e', 'City'),
            sort: 'address/city/name',
        },
        ...(!props?.hideSchemeColumn
            ? [
                  {
                      key: 'scheme',
                      header: t('41323917ef8089432959a3c33269debf', 'Scheme'),
                  },
              ]
            : []),
        {
            key: 'residenceType',
            header: t('2d02d77d09fdf4c4466181594b8d8739', 'Residence type'),
            sort: 'residenceType/name',
        },
        {
            key: 'defaultDevice',
            header: t('6c4c3c1e466c26dca47ded5d993858ea', 'Default device'),
            sort: 'defaultDisplayedDeviceCode',
        },
    ];

    return (
        <EdsTable
            searchFilterStore={searchFilterStore}
            searchTooltipKeywords={[
                t('1f4a9685e37e4f940d07a9f6b43dc83c', 'Street'),
                t('c8a03a9875d7fc4c023e1bebb23d07af', 'Postal code'),
                t('4ed5d2eaed1a1fadcc41ad1d58ed603e', 'City'),
                t('61a0a33cf99a58d9cad0d5ed6deae269', 'House number'),
                t('57d50ee4873d42049cc021a5df38b750', 'Device code'),
                t('9b88e58612797d6b989681a16621ad63', 'Phone number'),
            ]}
            availableFilters={[
                {
                    id: 'address/',
                    name: t('d5189de027922f81005951e6efe0efd5', 'Location'),
                    type: EdsTableFilterType.Location,
                },
                {
                    id: 'address/streetName',
                    name: t('1f4a9685e37e4f940d07a9f6b43dc83c', 'Street'),
                    type: EdsTableFilterType.Text,
                    caseSensitive: false,
                },
                {
                    id: 'address/houseNumber',
                    name: t('61a0a33cf99a58d9cad0d5ed6deae269', 'House number'),
                    type: EdsTableFilterType.Range,
                },
                {
                    id: 'address/apartmentNumber',
                    name: t(
                        'c2127dce4ee7d70840144b856dd36fe8',
                        'Apartment number / Detail'
                    ),
                    type: EdsTableFilterType.Range,
                },
                {
                    id: 'address/postalCode',
                    name: t('c8a03a9875d7fc4c023e1bebb23d07af', 'Postal code'),
                    type: EdsTableFilterType.Text,
                },
                {
                    id: 'schemeId',
                    name: t('41323917ef8089432959a3c33269debf', 'Scheme'),
                    type: EdsTableFilterType.MultiSelect,
                    getDataCallback: async () => getSchemes(),
                },
                {
                    id: 'isSchemeApartment',
                    name: t(
                        'cb4ca34e1faa1f62d0acaed117297aae',
                        'Scheme apartment'
                    ),
                    type: EdsTableFilterType.YesNo,
                },
                {
                    id: 'isSchemeManagerAddress',
                    name: t(
                        'b55af68ee67fab243ffe6ee107da7fab',
                        'Scheme manager address'
                    ),
                    type: EdsTableFilterType.YesNo,
                },
                // TODO UMO-609 Tags filter
            ]}
            headers={headers}
            searchClientSide={false}
            getDataCallback={getDataCallback}
            mappingCallback={mappingCallback}
            fetchServerSideDataOnInit={!_.isUndefined(baseFilter)}
            permissions={{
                new: ResidenceCreatePermissionSet,
            }}
            {...props}
        ></EdsTable>
    );
};
