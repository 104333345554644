import { PasswordInput, TextInput } from '@carbon/react';
import './eds-text-input.scss';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm, useFormGroup } from '../eds-form';
import _ from 'lodash';

export const EdsTextInput = forwardRef((props, ref) => {
    const {
        name,
        label,
        placeholder,
        showPasswordLabel,
        hidePasswordLabel,
        forceRequired,
        onChangeCallback,
        readOnly,
        disabled,
    } = props;
    const {
        getFormValue,
        handleFormChange,
        handleOnBlur,
        formatLabel,
        isInvalid,
        getInvalidText,
    } = useForm();
    const { prefix, usePrefix } = useFormGroup();

    let prefixedName = usePrefix(name) ?? name;
    const [inputPlaceholder] = useState(placeholder);

    const spaceChar = props.spacechar ?? '-';

    useImperativeHandle(ref, () => ({
        updateValue,
        appendText,
    }));

    const getStyle = () => {
        let classes = ['eds-text-input'];
        if (props.uppercase) {
            classes.push('uppercase');
        }
        return classes.join(' ');
    };

    const getValue = () => {
        if (!_.isUndefined(props?.uncontrolledValue)) {
            return props?.uncontrolledValue;
        }

        const value = getFormValue(name, prefix);
        let returnValue = '';

        if (!_.isUndefined(value) && !_.isNull(value)) {
            if (!_.isPlainObject(value)) {
                returnValue = value;
            } else if ('value' in value) {
                returnValue = value[value];
            }
        }

        return adaptValue(returnValue);
    };

    const adaptValue = (value) => {
        let returnValue = value;

        if (props.uppercase) {
            returnValue = returnValue.toUpperCase();
        }

        if (props.nospaces) {
            returnValue = returnValue.replace(/\s/g, spaceChar);
        }

        return returnValue;
    };

    const updateValue = (value) => {
        handleOnChange({
            target: {
                name: prefixedName,
                value: value,
                type: 'text',
            },
        });
    };

    const appendText = (text = '') => {
        if (!_.isEmpty(text)) {
            const currentText = getValue() ?? '';
            const newText = [currentText.trim(), text]
                .filter(Boolean)
                .join(' ');
            updateValue(newText);
        }
    };

    const handleOnChange = async (event) => {
        event.name = prefixedName;
        if (event?.target?.value) {
            event.target.value = adaptValue(event.target.value);
        }

        handleFormChange(event);

        if (_.isFunction(onChangeCallback)) {
            onChangeCallback(event);
        }
    };

    return props.password ? (
        <PasswordInput
            className={getStyle()}
            id={prefixedName}
            name={prefixedName}
            labelText={formatLabel(label, name, prefix, forceRequired)}
            placeholder={inputPlaceholder}
            showPasswordLabel={showPasswordLabel}
            hidePasswordLabel={hidePasswordLabel}
            tooltipPosition="top"
            value={getValue()}
            onChange={(event) => {
                handleOnChange(event);
            }}
            onBlur={handleOnBlur}
            invalid={isInvalid(prefixedName)}
            invalidText={getInvalidText(prefixedName)}
            disabled={disabled}
        />
    ) : (
        <TextInput
            className={getStyle()}
            id={prefixedName}
            name={prefixedName}
            labelText={formatLabel(label, name, prefix, forceRequired)}
            placeholder={inputPlaceholder}
            value={getValue()}
            onChange={(event) => {
                handleOnChange(event);
            }}
            onBlur={handleOnBlur}
            invalid={isInvalid(prefixedName)}
            invalidText={getInvalidText(prefixedName)}
            readOnly={readOnly}
            disabled={disabled}
        />
    );
});
EdsTextInput.displayName = 'EdsTextInput';
