import {
    createContext,
    memo,
    useCallback,
    useContext,
    useReducer,
} from 'react';
import { v4 as uuid } from 'uuid';
import { ModalActions, modalReducer } from './reducer';
import { getLogger, useAuth, useNotifications } from '../';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const logger = getLogger('ModalProvider');

const ModalContext = createContext({
    modal: [],
    showModal: () => null,
    showFormModal: () => null,
    showReplaceModal: () => null,
    showDeleteModal: () => null,
    showIdleModal: () => null,
    showAuditLogModal: () => null,
    showLinkModal: () => null,
    showUnlinkModal: () => null,
    showConfirmationModal: () => null,
    remove: () => null,
});

export const ModalProvider = memo(({ children }) => {
    const { showError, showSuccess } = useNotifications();
    const { logout } = useAuth();
    const { t } = useTranslation();
    const [state, dispatch] = useReducer(modalReducer, {
        modal: [],
    });

    const remove = useCallback((id) => {
        dispatch({
            type: ModalActions.RemoveModal,
            id: id,
        });
    }, []);

    const showModal = useCallback((params) => {
        let modal = {
            id: uuid(),
            ...params,
        };

        dispatch({
            type: ModalActions.ShowModal,
            payload: modal,
        });

        if (_.isFunction(modal?.onShow)) {
            modal.onShow();
        }
    }, []);

    const showFormModal = useCallback(
        (params) => {
            let title;
            const formData = params.formData ?? {};
            if ('id' in formData) {
                title = params.editTitle;
            } else {
                title = params.newTitle;
            }
            let onSubmitFormResponse;
            if (_.isFunction(params.onSubmitFormResponse)) {
                onSubmitFormResponse = params.onSubmitFormResponse;
            } else {
                onSubmitFormResponse = (response, removeModal) => {
                    if (formData?.id) {
                        params.tableRef?.current?.updateTableRow(response);
                    } else {
                        params.tableRef?.current?.insertTableRow(response);
                    }
                    removeModal();
                };
            }
            showModal({
                title: title,
                onRequestSubmit: (event, removeModal) => {
                    const submittedForm =
                        params.formRef.current?.submitForm(event);

                    submittedForm
                        .then((response) => {
                            onSubmitFormResponse(response, removeModal);
                        })
                        .catch((error) => {
                            logger.log('error', error);
                        });
                },
                ...params,
            });
        },
        [showModal]
    );

    const showReplaceModal = useCallback(
        (params) => {
            if (_.isNil(params.fromName) || _.isNil(params.toName)) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        '2168f1f82f2fea287a6f176c2dffa183',
                        'Unable to replace'
                    ),
                });
                return;
            }
            showModal({
                title: t('9dde360102c103867bd2f45872f1129c', 'Replace'),
                children: t(
                    '76342898fe4953c8e732b58c1981f4be',
                    "Are you sure you want to replace '{{from}}' with '{{to}}'?",
                    { from: params.fromName, to: params.toName }
                ),
                primaryButtonText: t('a6105c0a611b41b08f1209506350279e', 'Yes'),
                secondaryButtonText: t(
                    '7fa3b767c460b54a2be4d49030b349c7',
                    'No'
                ),
                danger: true,
                size: 'sm',
                onRequestSubmit: async (event, removeModal) => {
                    try {
                        if (!_.isFunction(params.replaceHandler)) {
                            throw Error('replaceHandler is not a function');
                        }
                        await params.replaceHandler();

                        showSuccess({
                            title: t(
                                '91bb248359043fe98416e259c9bdf10d',
                                'Replaced'
                            ),
                            content: t(
                                'ccbc39f696d7c1f53b292f0e791d6641',
                                'Succesfully replaced'
                            ),
                        });

                        if (params.fetchInitData) {
                            params.tableRef.current?.fetchInitData();
                        } else {
                            params.tableRef.current?.removeTableRow(params.row);
                        }
                    } catch {
                        showError({
                            title: t(
                                'cb5e100e5a9a3e7f6d1fd97512215282',
                                'Error'
                            ),
                            content: t(
                                '2168f1f82f2fea287a6f176c2dffa183',
                                'Unable to replace'
                            ),
                        });
                    }
                    removeModal();
                },
                ...params,
            });
        },
        [showModal, showError, showSuccess, t]
    );

    const showDeleteModal = useCallback(
        async (params) => {
            if (_.isNil(params.name)) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'c76b3385e54ac931bb544667d9d3d260',
                        'Unable to delete'
                    ),
                });
                return;
            }

            if (_.isFunction(params.validateDeleteHandler)) {
                const isValid = await params.validateDeleteHandler();
                if (!isValid) {
                    return;
                }
            }

            showModal({
                title: t('099af53f601532dbd31e0ea99ffdeb64', 'Delete'),
                children: t(
                    'fd8502f1c456a29e63bfc3af5dffc28c',
                    "Are you sure you want to delete '{{name}}'?",
                    {
                        name: params.name,
                    }
                ),
                primaryButtonText: t('a6105c0a611b41b08f1209506350279e', 'Yes'),
                secondaryButtonText: t(
                    '7fa3b767c460b54a2be4d49030b349c7',
                    'No'
                ),
                danger: true,
                size: 'xs',
                onRequestSubmit: async (event, removeModal) => {
                    try {
                        if (!_.isFunction(params.deleteHandler)) {
                            throw Error('deleteHandler is not a function');
                        }
                        await params.deleteHandler();

                        showSuccess({
                            title: t(
                                'da602f0b162fccbf6b150cfcfc7a7379',
                                'Deleted'
                            ),
                            content: t(
                                '6a14e4e9bb4b61c775bb41832d90273e',
                                'Succesfully deleted'
                            ),
                        });

                        if (params.fetchInitData) {
                            params.tableRef.current?.fetchInitData();
                        } else {
                            params.tableRef.current?.removeTableRow(params.row);
                        }
                    } catch {
                        showError({
                            title: t(
                                'cb5e100e5a9a3e7f6d1fd97512215282',
                                'Error'
                            ),
                            content: t(
                                'c76b3385e54ac931bb544667d9d3d260',
                                'Unable to delete'
                            ),
                        });
                    }
                    removeModal();
                },
                ...params,
            });
        },
        [showModal, showError, showSuccess, t]
    );

    const showConfirmationModal = useCallback(
        (params) => {
            showModal({
                title:
                    params.title ??
                    t('270442279d15bb2ced1ee5c611412731', 'Please confirm'),
                size: 'xs',
                children: params.question,
                primaryButtonText: t('a6105c0a611b41b08f1209506350279e', 'Yes'),
                secondaryButtonText: t(
                    '7fa3b767c460b54a2be4d49030b349c7',
                    'No'
                ),
                onRequestSubmit: async (event, removeModal) => {
                    if (_.isFunction(params.yesHandler)) {
                        await params.yesHandler();
                    }

                    removeModal();
                },
                onSecondarySubmit: async (event, removeModal) => {
                    if (_.isFunction(params.noHandler)) {
                        await params.noHandler();
                    }

                    removeModal();
                },
                danger: params.danger ?? false,
            });
        },
        [showModal, t]
    );

    const showUnlinkModal = useCallback(
        (params) => {
            if (_.isNull(params.name)) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        '7f9885ba8d06a5e64b9af4e342fd04f5',
                        'Unable to unlink'
                    ),
                });
                return;
            }
            showModal({
                title: t('695630cfc5eb92580fb3e76a0c790e63', 'Unlink'),
                children: t(
                    'e56f9ed44fe705f6f32c8e6ab6d9ecf4',
                    "Are you sure you want to unlink '{{name}}'?",
                    {
                        name: params.name,
                    }
                ),
                primaryButtonText: t('a6105c0a611b41b08f1209506350279e', 'Yes'),
                secondaryButtonText: t(
                    '7fa3b767c460b54a2be4d49030b349c7',
                    'No'
                ),
                danger: true,
                size: 'xs',
                onRequestSubmit: async (event, removeModal) => {
                    try {
                        if (!_.isFunction(params.unlinkHandler)) {
                            throw Error('unlinkHandler is not a function');
                        }
                        await params.unlinkHandler();

                        showSuccess({
                            title: t(
                                'b10d9270192e39a728e477196e51ae59',
                                'Unlinked'
                            ),
                            content: t(
                                '6c395dc72eab3ea79cd10789f3510183',
                                'Succesfully unlinked'
                            ),
                        });
                        if (!_.isUndefined(params.row)) {
                            params.tableRef.current?.removeTableRow(params.row);
                        }
                    } catch {
                        showError({
                            title: t(
                                'cb5e100e5a9a3e7f6d1fd97512215282',
                                'Error'
                            ),
                            content: t(
                                '7f9885ba8d06a5e64b9af4e342fd04f5',
                                'Unable to unlink'
                            ),
                        });
                    }
                    removeModal();
                },
                ...params,
            });
        },
        [showModal, showError, showSuccess, t]
    );

    const showIdleModal = useCallback(() => {
        showModal({
            title: t('c90d425d6d36008f8cfdefcd6be7ef41', 'Session expiration'),
            children: t(
                '86c4189e1d7754951230ec06a722f61b',
                'Your session is about to expire. Do you want to extend your session to stay logged in?'
            ),
            size: 'xs',
            primaryButtonText: t(
                'b188c30b9f75094c0b9fbe14aa0c1cab',
                'Extend session'
            ),
            secondaryButtonText: t(
                'aa68727169062eee9df160cddb07f3e8',
                'Logout now'
            ),
            onRequestSubmit: (_event, removeModal) => {
                removeModal();
            },
            onSecondarySubmit: () => {
                logout();
            },
        });
    }, [showModal, logout, t]);

    const showAuditLogModal = useCallback(
        (params) => {
            showModal({
                title: `${t(
                    '7d71019726dccc04ceb52b94f892b7f6',
                    'Audit log'
                )} - ${params.name}`,
                secondaryButtonText: t(
                    '716f6b30598ba30945d84485e61c1027',
                    'Close'
                ),
                size: 'md',
                hidePrimaryButton: true,
                children: params.children,
            });
        },
        [showModal, t]
    );

    const showLinkModal = useCallback(
        (params) => {
            showModal({
                title: params.title,
                size: params.size ?? 'md',
                hidePrimaryButton: params.hidePrimaryButton ?? true,
                hideSecondaryButton: params.hideSecondaryButton ?? true,
                secondaryButtonText: t(
                    '716f6b30598ba30945d84485e61c1027',
                    'Close'
                ),
                children: params.children,
            });
        },
        [showModal, t]
    );

    return (
        <ModalContext.Provider
            value={{
                ...state,
                showModal,
                showFormModal,
                showReplaceModal,
                showDeleteModal,
                showIdleModal,
                showAuditLogModal,
                showLinkModal,
                showUnlinkModal,
                showConfirmationModal,
                remove,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
});
ModalProvider.displayName = 'ModalProvider';

export const useModal = () => useContext(ModalContext);
export const ModalConsumer = ModalContext.Consumer;
