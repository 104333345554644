import 'intl-tel-input/build/css/intlTelInput.css';
import IntlTelInput from 'intl-tel-input/reactWithUtils';
import './eds-phone-number.scss';
import { useEffect, useRef, useState } from 'react';
import { WarningFilled } from '@carbon/icons-react';
import { useForm, useFormGroup } from '../eds-form';
import { useTranslation } from 'react-i18next';
import {
    useMutationObserver,
    setPositionOfDropdownElement,
    handleScroll,
    useEffectOnMount,
    getCountryTranslations,
    getDefaultCountry,
} from '../../../features';
import _ from 'lodash';
import { TextInputSkeleton } from '@carbon/react';

export function EdsPhoneNumber({ validateE164Format = true, ...props }) {
    const { prefix, usePrefix } = useFormGroup();
    const {
        form,
        getFormValue,
        handleFormChange,
        handleOnBlur,
        formatLabel,
        isInvalid,
        getInvalidText,
    } = useForm();
    const inputRef = useRef(null);
    const [intlTelInputElement, setIntlTelInputElement] = useState();
    const [name] = useState(props.name ?? '');
    let prefixedName = usePrefix(name) ?? name;
    const [labelText, setlabelText] = useState(props.label);
    const { t } = useTranslation();
    const [isInitializing, setIsInitializing] = useState(true);
    const [initialCountry, setInitialCountry] = useState('');
    const dropdownOffset = { x: 1, y: -3 };
    const scrollListener = useRef(null);

    const [number, setNumber] = useState();
    const getValue = () => {
        const value = getFormValue(name, prefix);

        let returnValue = '';
        if (!_.isUndefined(value) && !_.isNull(value)) {
            if (!_.isPlainObject(value)) {
                returnValue = value;
            } else if ('value' in value) {
                returnValue = value[value];
            }

            if (_.isUndefined(number)) {
                setNumber(returnValue);
            }
        }
        return returnValue;
    };

    useEffect(() => {
        const loadDefault = async () => {
            const defaultCountry = await getDefaultCountry();
            if (!_.isNil(defaultCountry?.countryCode)) {
                setInitialCountry(defaultCountry.countryCode);
            }
        };
        loadDefault();
    }, []);

    useEffectOnMount(() => {
        if (_.isFunction(props.onInitValue)) {
            props.onInitValue(getValue());
        }
    });

    useEffect(() => {
        if (!_.isUndefined(validateE164Format)) {
            if (!validateE164Format) {
                setlabelText(
                    `${props.label}  (${t(
                        '829a642b94a3a04efadc176b40b1bb05',
                        'Service number'
                    )})`
                );
            } else {
                setlabelText(props.label);
            }
        }
    }, [validateE164Format, props.label, t]);

    const getAllPhoneNumberInputs = () => {
        if (_.isNull(inputRef.current)) {
            return [];
        }

        return inputRef.current.getElementsByClassName(
            'eds-phone-number-input'
        );
    };

    const correctValueForNumberInputs = (newValue) => {
        //method needed for the two IntlTelInput elements.  The input elemts of the hidden component doens't get updated when the input of the visible phone number element changes
        //this methods fixes this issue that when switching betwee
        const inputs = getAllPhoneNumberInputs();
        let n;
        for (n = 0; n < inputs.length; ++n) {
            inputs[n].value = newValue;
        }
    };

    const getIntlTelInputElement = (dropdownContent) => {
        const grandParent = dropdownContent.parentNode.parentNode;
        const list = grandParent.getElementsByClassName('iti__tel-input');
        return list.length ? list[0] : undefined;
    };

    const getDropdownContentElement = (intlTelInputElement) => {
        const list = intlTelInputElement.parentNode.getElementsByClassName(
            'iti__dropdown-content'
        );
        return list.length ? list[0] : undefined;
    };

    const observerCallback = (records) => {
        const dropdownRecords = records.filter(
            (record) =>
                record.attributeName === 'class' &&
                record.target.classList.contains('iti__dropdown-content')
        );

        if (!dropdownRecords.length) {
            return;
        }

        const dropdownContent = dropdownRecords[0].target;
        if (dropdownContent.classList.contains('iti__hide')) {
            setIntlTelInputElement(undefined);
        } else {
            const intlTelInputElement = getIntlTelInputElement(dropdownContent);
            setIntlTelInputElement(intlTelInputElement);

            setPositionOfDropdownElement(
                intlTelInputElement,
                dropdownContent,
                dropdownOffset
            );
        }
    };

    useMutationObserver(inputRef, observerCallback);

    const handleCountryDropdownClose = (event) => {
        const floatingEl = getDropdownContentElement(event.currentTarget);
        floatingEl.style.display = null;
    };

    useEffect(() => {
        if (
            intlTelInputElement &&
            !_.isUndefined(intlTelInputElement) &&
            !_.isNull(intlTelInputElement)
        ) {
            const floatingEl = getDropdownContentElement(intlTelInputElement);
            const params = {
                referenceEl: intlTelInputElement,
                floatingEl: floatingEl,
                dropdownOffset: dropdownOffset,
                onCloseCallback: () =>
                    document.dispatchEvent(
                        new KeyboardEvent('keydown', { key: 'Escape' })
                    ),
            };

            scrollListener.current = handleScroll.bind(null, params);
            document.addEventListener('scroll', scrollListener.current, true);
        } else {
            document.removeEventListener(
                'scroll',
                scrollListener.current,
                true
            );
        }

        return () => {
            document.removeEventListener(
                'scroll',
                scrollListener.current,
                true
            );
        };
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intlTelInputElement]);

    useEffect(() => {
        if (!intlTelInputElement) {
            return;
        }

        intlTelInputElement.addEventListener(
            'close:countrydropdown',
            handleCountryDropdownClose
        );

        return () => {
            intlTelInputElement.removeEventListener(
                'close:countrydropdown',
                handleCountryDropdownClose
            );
        };
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intlTelInputElement]);

    useEffect(() => {
        setTimeout(function () {
            // add a delay to prevent flickering when the phone number type changes on load
            setIsInitializing(Object.keys(form).length === 0);
        }, 100);
    }, [form, props]);

    useEffect(() => {
        if (_.isUndefined(number)) {
            return;
        }

        if (number !== getValue()) {
            handleOnChangeNumber(number);
        }

        correctValueForNumberInputs(number);
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [number]);

    const handleOnChangeNumber = (number) => {
        if (Object.keys(form).length === 0) {
            return;
        }

        const event = {
            target: {
                name: prefixedName,
                value: number,
                type: 'text',
            },
        };
        handleFormChange(event);

        if (_.isFunction(props.onChangeCallback)) {
            props.onChangeCallback(event);
        }
    };

    const handleOnInputBlur = (event) => {
        event.target.name = prefixedName;
        handleOnBlur(event);
    };

    const getIntlTelInputClasses = () => {
        let classes = [
            'eds-phone-number-input',
            'cds--text-input',
            'iti__tel-input',
        ];

        if (isInvalid(prefixedName)) {
            classes.push('cds--text-input--invalid');
        }
        return classes.join(' ');
    };

    const getWrapperStyle = (hidden) => {
        let classes = ['eds-phone-number-wrapper'];

        if (hidden) {
            classes.push('hidden');
        }
        return classes.join(' ');
    };

    const geti18n = () => {
        let countries = getCountryTranslations(t);
        return {
            ...countries,
            selectedCountryAriaLabel: t(
                'b28c1c95b5a3716b9b14675c59c8459c',
                'Selected country'
            ),
            noCountrySelected: t(
                '79c6a0d56646242280511c4fca84d7dd',
                'No country selected'
            ),
            countryListAriaLabel: t(
                '3f5dc3f4b5e8f72cd4621c04a1feb4e5',
                'List of countries'
            ),
            searchPlaceholder: t('06a943c59f33a34bb5924aaf72cd2995', 'Search'),
            zeroSearchResults: t(
                '12d60b6f0d82daa965ad2a2b655cb1ea',
                'No results found'
            ),
            oneSearchResult: t(
                '26ae66997f5e269557540e9b55870b34',
                '1 result found'
            ),
            multipleSearchResults: t(
                '80959fef02b43911571be51f1e52f384',
                '${count} results found'
            ),
        };
    };

    if (isInitializing) {
        return (
            <div className="eds-phone-number skeleton">
                <TextInputSkeleton />
            </div>
        );
    }

    return (
        <>
            <div
                ref={inputRef}
                className="eds-phone-number"
                data-name={prefixedName}
            >
                {labelText && (
                    <>
                        <label className="cds--label" htmlFor={prefixedName}>
                            {formatLabel(
                                labelText,
                                name,
                                prefix,
                                props?.forceRequired
                            )}
                        </label>
                    </>
                )}
                <div
                    data-invalid={isInvalid(prefixedName) || null}
                    className="cds--text-input__field-wrapper"
                >
                    <span>
                        {isInvalid(prefixedName) && (
                            <WarningFilled className="cds--text-input__invalid-icon" />
                        )}
                    </span>

                    <span className={getWrapperStyle(!validateE164Format)}>
                        <IntlTelInput
                            inputProps={{
                                className: getIntlTelInputClasses(),
                            }}
                            id={prefixedName}
                            initialValue={getValue()}
                            onChangeNumber={(number) => {
                                setNumber(number);
                            }}
                            onBlur={handleOnInputBlur}
                            initOptions={{
                                initialCountry: initialCountry,
                                containerClass: 'cds--combo-box cds--list-box',
                                i18n: geti18n(),
                            }}
                        />
                    </span>
                    <span className={getWrapperStyle(validateE164Format)}>
                        <IntlTelInput
                            inputProps={{
                                className: getIntlTelInputClasses(),
                            }}
                            id={prefixedName}
                            initialValue={getValue()}
                            onChangeNumber={(number) => {
                                setNumber(number);
                            }}
                            onBlur={handleOnInputBlur}
                            initOptions={{
                                allowDropdown: false,
                                showFlags: false,
                                containerClass: 'cds--combo-box cds--list-box',
                                i18n: geti18n(),
                            }}
                        />
                    </span>
                </div>

                {isInvalid(prefixedName) && (
                    <div
                        className="cds--form-requirement"
                        id="description-error-msg"
                        dir="auto"
                    >
                        {getInvalidText(prefixedName)}
                    </div>
                )}
            </div>
        </>
    );
}
