import {
    getAlarm,
    getAlarmDevicePositions,
    getTenantGoogleMapsSettings,
} from '../../../../features';
import { redirectPageLoaderError } from '../../../../features/utils/loader';

export const pageLoader = async ({ params }) => {
    try {
        if (params.path && params.path === 'location') {
            return await getAlarmDevicePositions(params.alarmId);
        }

        return await getAlarm(params.alarmId);
    } catch (ex) {
        return redirectPageLoaderError(ex);
    }
};

export const pageLoaderAlarmDevicePosition = async ({ params }) => {
    try {
        let requests = [];
        requests.push(getTenantGoogleMapsSettings());
        requests.push(getAlarmDevicePositions(params.alarmId));

        const response = await Promise.all(requests);
        return {
            googleMapsSettings: response[0] ?? {},
            devicePositions: response[1] ?? [],
        };
    } catch {
        return {};
    }
};
