import { useEffect, useState } from 'react';
import { useForm } from '../../../eds/eds-form';
import { EdsFormGroup, EdsNotification, EdsWizardStep } from '../../../eds';
import { UmoSearchProfessionalCaregiverTable } from '../umo-search-professional-caregiver-table';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

function SearchOrNewStep({ id, selectedRow, filterIds = [], ...props }) {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(selectedRow);
    const { updateFormValues, isInvalid } = useForm();

    useEffect(() => {
        props.selectedRowCallback(selected);
    }, [props, selected]);

    useEffect(() => {
        if (!_.isUndefined(selected) && _.isString(selected)) {
            updateFormValues({ ['caregiver.caregiverId']: selected });
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const formDefinition = {
        wizardStepId: id,
        caregiverId: {
            validation: {
                required: true,
            },
        },
    };

    const getBaseFilter = () => {
        const ids = filterIds ?? [];

        let baseFilter = 'disabled eq false';
        if (!_.isEmpty(ids)) {
            baseFilter += ` and (id in (${ids
                .map((i) => `'${i}'`)
                .join(',')}) eq false)`;
        }

        return baseFilter;
    };

    return (
        <EdsWizardStep
            id={id}
            label={t(
                '61c3faea81769efbe2c213ed28c3a3b5',
                'Search professional caregiver'
            )}
        >
            <EdsFormGroup
                formDefinition={formDefinition}
                inStep={true}
                prefix="caregiver"
            >
                {isInvalid('caregiver.caregiverId') && (
                    <EdsNotification
                        notification={{
                            hideCloseButton: true,
                            kind: 'warning',
                            title: t(
                                'c40b077f98cb056eb6e3b29e6c2df752',
                                'Please select a professional caregiver to link'
                            ),
                            inline: true,
                        }}
                    />
                )}

                <EdsFormGroup
                    prefix="caregiver"
                    formDefinition={formDefinition}
                    inStep={props.wizardStepId ?? false}
                >
                    <UmoSearchProfessionalCaregiverTable
                        onRowClick={(row) => {
                            if (row.id !== selected) {
                                setSelected(row.id);
                            }
                        }}
                        onSelectionButtonClick={(row) => {
                            if (row.id !== selected) {
                                setSelected(row.id);
                            }
                        }}
                        selected={[selected]}
                        enableRadioSelection={true}
                        size="md"
                        enableSearchParams={false}
                        searchFilterStore={undefined}
                        fetchServerSideDataOnInit={true}
                        enableSearch={true}
                        baseFilter={getBaseFilter()}
                    />
                </EdsFormGroup>
            </EdsFormGroup>
        </EdsWizardStep>
    );
}

export default SearchOrNewStep;
