import {
    createContext,
    memo,
    useCallback,
    useContext,
    useReducer,
} from 'react';
import { v4 as uuid } from 'uuid';
import { NotificationsActions, notificationsReducer } from './reducer';
import _ from 'lodash';
import { getLogger } from '../utils';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const logger = getLogger('NotificationsProvider');

const NotificationsContext = createContext({
    notifications: [],
    showError: () => null,
    showWarning: () => null,
    showSuccess: () => null,
    showInfo: () => null,
    showDesktopNotification: () => null,
    remove: () => null,
});

export const NotificationType = Object.freeze({
    Internal: 'INTERNAL',
    Desktop: 'DESKTOP',
    Both: 'BOTH',
});

export const NotificationsProvider = memo(({ children }) => {
    const { t } = useTranslation();
    const [state, dispatch] = useReducer(notificationsReducer, {
        notifications: [],
    });

    let showNotification, hasDesktopNotificationPermission;

    const remove = useCallback((id) => {
        dispatch({
            type: NotificationsActions.RemoveNotification,
            id: id,
        });
    }, []);

    hasDesktopNotificationPermission = useCallback(() => {
        if (!('Notification' in window)) {
            logger.warn('This browser does not support desktop notification');
            return false;
        } else if (Notification.permission === 'granted') {
            logger.info('Desktop notification permission granted');
            return true;
        } else if (Notification.permission === 'denied') {
            logger.warn('Desktop notification permission denied');
            return false;
        } else {
            // We need to ask for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts,
                if (permission === 'granted') {
                    showNotification('success', {
                        title: t(
                            '38ed0344984deef351f324d371c99578',
                            'Permission set'
                        ),
                        content: t(
                            '1ced96cd84b5c1fc73f7b8b2881cc69c',
                            'Permission granted to display system notifications.'
                        ),
                        type: NotificationType.Both,
                        ttl: 8000,
                    });
                } else {
                    showNotification('error', {
                        title: t(
                            '38ed0344984deef351f324d371c99578',
                            'Permission set'
                        ),
                        content: t(
                            '033447c10aed6ab1936f2e68fac5ce32',
                            'You have denied this site the permission to display system notifications.'
                        ),
                        ttl: 0, //Do not auto remove. User must remove the message self so it is more likely that the message is read.
                    });
                }
            });
            logger.info('Desktop notification asking permission.');
            return false;
        }
    }, [showNotification, t]);

    /*
     * Uses the same properties as ActionableNotification:
     * https://react.carbondesignsystem.com/?path=/docs/components-notifications-actionable--overview
     */
    showNotification = useCallback(
        (kind, { ttl = 3000, type, ...params }) => {
            let notificationType = type ?? NotificationType.Internal;

            let notification = {
                id: uuid(),
                kind: kind,
                ...params,
            };

            if (
                notificationType === NotificationType.Desktop ||
                notificationType === NotificationType.Both
            ) {
                if (hasDesktopNotificationPermission()) {
                    newDesktopNotification(notification);
                } else if (notificationType !== NotificationType.Both) {
                    //if no permissions make sure the notification is shown as internal notification
                    notificationType = NotificationType.Internal;
                }
            }

            if (
                notificationType === NotificationType.Internal ||
                notificationType === NotificationType.Both
            ) {
                dispatch({
                    type: NotificationsActions.ShowNotification,
                    payload: notification,
                });

                if (_.isFunction(notification.onShow)) {
                    notification.onShow();
                }

                if (ttl > 0) {
                    setTimeout(() => remove(notification.id), ttl);
                }
            }
        },
        [remove, hasDesktopNotificationPermission]
    );

    const newDesktopNotification = ({
        title,
        content,
        lang,
        id,
        data,
        silent = false,
        ...props
    }) => {
        var options = {
            body: content,
            lang: lang ?? i18n.language,
            tag: id ?? uuid(),
            data: data,
            silent: silent,
        };

        const desktopNotification = new Notification(title, options);
        desktopNotification.onclick = () => {
            if (_.isFunction(props.onClick)) {
                props.onClick();
            }
        };
    };

    const showError = useCallback(
        (notification) => showNotification('error', notification),
        [showNotification]
    );

    const showWarning = useCallback(
        (notification) => showNotification('warning', notification),
        [showNotification]
    );

    const showSuccess = useCallback(
        (notification) => showNotification('success', notification),
        [showNotification]
    );

    const showInfo = useCallback(
        (notification) => showNotification('info', notification),
        [showNotification]
    );

    const showDesktopNotification = useCallback(
        (notification) =>
            showNotification('info', {
                type: NotificationType.Desktop,
                ...notification,
            }),
        [showNotification]
    );

    return (
        <NotificationsContext.Provider
            value={{
                ...state,
                showError,
                showWarning,
                showSuccess,
                showInfo,
                showDesktopNotification,
                remove,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
});
NotificationsProvider.displayName = 'NotificationsProvider';

export const useNotifications = () => useContext(NotificationsContext);
export const NotificationsConsumer = NotificationsContext.Consumer;
