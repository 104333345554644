import { lazy } from 'react';
import { Outlet, redirect } from 'react-router-dom';
import { AlarmReadPermissionSet, Restricted } from '../../../features';
import { pageLoader as AlarmPageLoader } from './loader';
import { pageMapsTabLoader as AlarmMapsLoader } from './loader';
import { pageDevicesTabLoader as AlarmDevicesLoader } from './loader';
import { pageDeviceDetailsTabLoader as AlarmDeviceDetailsLoader } from './loader';
import { pageProceduresTabLoader as AlarmProceduresLoader } from './loader';
import { pageProcedureDetailsTabLoader as AlarmProcedureDetailsLoader } from './loader';

const AlarmDetailPage = lazy(() => import('./alarm-detail-page'));

const OverviewTab = lazy(() => import('./overview-tab'));
const ClientTab = lazy(() => import('./client-tab'));
const CaregiversTab = lazy(() => import('./caregivers-tab'));
const NotesTab = lazy(() => import('./notes-tab'));
const DevicesTab = lazy(() => import('./devices-tab'));
const DevicesDetailsTab = lazy(() => import('./devices-details-tab'));
const MedicalTab = lazy(() => import('./medical-tab'));
const HistoryTab = lazy(() => import('./history-tab'));
const ProceduresTab = lazy(() => import('./procedures-tab'));
const ProceduresDetailsTab = lazy(() => import('./procedures-details-tab'));
const MapTab = lazy(() => import('./map-tab'));
const DocumentsTab = lazy(() => import('./documents-tab'));
const ReportsTab = lazy(() => import('./reports-tab'));

const AlarmPageRoutes = {
    path: 'alarm-detail',
    element: (
        <Restricted toAnyAccount={AlarmReadPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    children: [
        {
            index: true,
            loader: () => redirect('/alarm'),
        },
        {
            id: 'alarm-detail/id',
            path: ':alarmId',
            element: <AlarmDetailPage />,
            loader: AlarmPageLoader,
            children: [
                {
                    index: true,
                    element: <OverviewTab />,
                    handle: {
                        //for tabs to find that this route is the index
                        index: true,
                    },
                },
                {
                    path: 'client',
                    element: <ClientTab />,
                    handle: {
                        crumb: (t) =>
                            t('62608e08adc29a8d6dbc9754e659f125', 'Client'),
                    },
                },
                {
                    path: 'caregivers',
                    element: <CaregiversTab />,
                    handle: {
                        crumb: (t) =>
                            t('bc77e7fcbeea66aa5fbcd998260f66f5', 'Caregivers'),
                    },
                },
                {
                    path: 'notes',
                    element: <NotesTab />,
                    handle: {
                        crumb: (t) =>
                            t('4358b5009c67d0e31d7fbf1663fcd3bf', 'Notes'),
                    },
                },
                {
                    id: 'alarm-detail/devices',
                    path: 'devices',
                    loader: AlarmDevicesLoader,
                    element: <DevicesTab />,
                    children: [
                        {
                            id: 'alarm-detail/devices/uid',
                            path: ':deviceUid',
                            element: <DevicesDetailsTab />,
                            loader: AlarmDeviceDetailsLoader,
                            handle: {
                                crumb: (t) =>
                                    t(
                                        'e0212e54ec3a2a120ca0d321b3a60c78',
                                        'Devices'
                                    ),
                            },
                        },
                    ],
                },
                {
                    path: 'medical',
                    element: <MedicalTab />,
                    handle: {
                        crumb: (t) =>
                            t('7cbdd4e997c3b8e759f8d579bb30f6f1', 'Medical'),
                    },
                },
                {
                    path: 'history',
                    element: <HistoryTab />,
                    handle: {
                        crumb: (t) =>
                            t('3cd15f8f2940aff879df34df4e5c2cd1', 'History'),
                    },
                },
                {
                    id: 'alarm-detail/procedures',
                    path: 'procedure',
                    loader: AlarmProceduresLoader,
                    element: <ProceduresTab />,
                    children: [
                        {
                            id: 'alarm-detail/procedures/id',
                            path: ':procedureId',
                            element: <ProceduresDetailsTab />,
                            loader: AlarmProcedureDetailsLoader,
                            handle: {
                                crumb: (t) =>
                                    t(
                                        '662609908ab8e0f372d83dea3511370b',
                                        'Procedure'
                                    ),
                            },
                        },
                    ],
                },
                {
                    id: 'alarm-detail/location',
                    path: 'map',
                    element: <MapTab />,
                    loader: AlarmMapsLoader,
                    handle: {
                        crumb: (t) =>
                            t('1d78dc8ed51214e518b5114fe24490ae', 'Map'),
                    },
                },
                {
                    path: 'documents',
                    element: <DocumentsTab />,
                    handle: {
                        crumb: (t) =>
                            t('21f64da1e5792c8295b964d159a14491', 'Documents'),
                    },
                },
                {
                    path: 'reports',
                    element: <ReportsTab />,
                    handle: {
                        crumb: (t) =>
                            t('a8445719836f2d5e8b51986410e14728', 'Reports'),
                    },
                },
            ],
        },
    ],
};

export default AlarmPageRoutes;
