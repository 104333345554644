import { Row } from '@carbon/react';
import { useEffect, useRef, useState } from 'react';
import {
    EdsDropdown,
    EdsDropdownType,
    EdsFormColumn,
    EdsFormGroup,
} from '../../';
import {
    debounce,
    defaultDropdownMappingCallback,
    getCitiesByCountry,
    getCitiesByRegion,
    getCountries,
    getCountryTranslations,
    getDistrictsByCity,
    getRegions,
} from '../../../../features';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useEffectOnMount } from '../../../../features/react/hooks';

export function EdsFormGroupLocation(props) {
    const [formDefinition, setFormDefinition] = useState([]);
    const countriesRef = useRef(null);
    const regionsRef = useRef(null);
    const citiesRef = useRef(null);
    const districtsRef = useRef(null);
    const [countryId, setCountryId] = useState();
    const [regionId, setRegionId] = useState(null);
    const [cityId, setCityId] = useState();
    const [prefix] = useState(props.prefix ?? null);
    const updateRegionTimeoutId = useRef();
    const { t } = useTranslation();

    const countryTranslations = getCountryTranslations(t);

    const countryMappingCallback = (data) => {
        return {
            id: data.id,
            text: countryTranslations[data.countryCode] || data.name,
            countryCode: data.countryCode,
        };
    };

    const cityMappingCallback = (data) => {
        return { id: data.id, text: data.name, regionId: data.regionId };
    };

    const getDefaultCountry = async () => {
        const countries = await getCountries();
        const defaultCountry = countries.find((country) => country.isDefault);
        return defaultCountry ? countryMappingCallback(defaultCountry) : {};
    };

    useEffectOnMount(async () => {
        setFormDefinition(await getFormDefinition());
    });

    useEffect(() => {
        // Update regions and cities on country id change
        updateRegions();
        updateCities();
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryId]);

    useEffect(() => {
        // Update cities on region id change
        updateCities();
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionId]);

    useEffect(() => {
        // Update districts on city id change
        updateDistricts();
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId]);

    const updateRegions = async () => {
        const regions = [];
        let regionsResponse = null;
        if (_.isNumber(countryId)) {
            regionsResponse = await getRegions(countryId);
        }
        if (_.isArray(regionsResponse)) {
            regionsResponse.map((data) => {
                regions.push(defaultDropdownMappingCallback(data));
            });
        }
        regionsRef.current?.updateItems(regions);
    };

    const updateRegion = async (cityRegionId) => {
        if (_.isNil(cityRegionId) || !_.isNil(regionId)) {
            return;
        }

        regionsRef.current?.updateSelectedItem(cityRegionId);
    };

    const updateCities = async () => {
        const cities = [];
        let citiesResponse = null;
        if (_.isNumber(regionId)) {
            citiesResponse = await getCitiesByRegion(regionId);
        } else if (_.isNumber(countryId)) {
            citiesResponse = await getCitiesByCountry(countryId);
        }

        if (_.isArray(citiesResponse)) {
            citiesResponse.map((data) => {
                cities.push(cityMappingCallback(data));
            });
        }
        citiesRef.current?.updateItems(cities);
    };

    const updateDistricts = async () => {
        const districts = [];
        let districstResponse = null;
        if (_.isNumber(cityId)) {
            districstResponse = await getDistrictsByCity(cityId);
        }

        if (_.isArray(districstResponse)) {
            districstResponse.map((data) => {
                districts.push(defaultDropdownMappingCallback(data));
            });
        }
        districtsRef.current?.updateItems(districts);
    };

    const getFormDefinition = async () => {
        return {
            countryId: {
                value:
                    props.selectedValues?.country ??
                    (await getDefaultCountry()),
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'id',
                dependents: ['postalCode'],
            },
            regionId: {
                value: props.selectedValues?.region,
                validation: {
                    type: 'dropdown',
                    required: false,
                },
                mapping: 'id',
            },
            cityId: {
                value: props.selectedValues?.city,
                validation: {
                    type: 'dropdown',
                    required: props.cityRequired,
                },
                mapping: 'id',
            },
            districtId: {
                value: props.selectedValues?.district,
                validation: {
                    type: 'dropdown',
                    required: false,
                },
                mapping: 'id',
            },
        };
    };

    return (
        <EdsFormGroup
            prefix={prefix}
            formDefinition={formDefinition}
            inStep={props.wizardStepId ?? false}
            label={
                props.label ?? t('d5189de027922f81005951e6efe0efd5', 'Location')
            }
        >
            <Row>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={countriesRef}
                        name={'countryId'}
                        label={t('e909c2d7067ea37437cf97fe11d91bd0', 'Country')}
                        type={EdsDropdownType.ComboBox}
                        getDataCallback={getCountries}
                        mappingCallback={countryMappingCallback}
                        onChangeCallback={(event) => {
                            const countryId = event.selectedItem?.id;
                            setCountryId(countryId);
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={regionsRef}
                        name={'regionId'}
                        label={t('960db2ed82202a9706b97775a4269378', 'Region')}
                        type={EdsDropdownType.ComboBox}
                        onChangeCallback={(event) => {
                            const regionId = event.selectedItem?.id;
                            setRegionId(regionId);
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
            </Row>
            <Row>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={citiesRef}
                        name={'cityId'}
                        type={EdsDropdownType.ComboBox}
                        label={t('4ed5d2eaed1a1fadcc41ad1d58ed603e', 'City')}
                        onChangeCallback={(event) => {
                            const cityId = event.selectedItem?.id;
                            setCityId(cityId);
                            const cityRegionId = event.selectedItem?.regionId;
                            debounce(
                                updateRegionTimeoutId,
                                () => {
                                    updateRegion(cityRegionId);
                                },
                                {
                                    trailing: true,
                                    delay: 300,
                                }
                            );
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={districtsRef}
                        name={'districtId'}
                        type={EdsDropdownType.ComboBox}
                        label={t(
                            '6b77ef4b602800a89d88e6e3f93a322c',
                            'District'
                        )}
                    ></EdsDropdown>
                </EdsFormColumn>
            </Row>
        </EdsFormGroup>
    );
}
